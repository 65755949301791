import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { TextbookAPI } from "(apis)/(shared-apis)/textbook-apis";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { PastPaperAPI } from "(apis)/(shared-apis)/past-paper-apis";

// Dropdown Options
export const SUBJECTS = [
  { value: "Physics", label: "Physics" },
  { value: "Chemistry", label: "Chemistry" },
  { value: "Biology", label: "Biology" },
  { value: "Pak Studies", label: "Pak Studies" },
  { value: "English", label: "English" },
  { value: "Urdu", label: "Urdu" },
  { value: "Islamiat", label: "Islamiat" } 
];

export const PROVINCES = [
  { value: "Punjab", label: "Punjab" },
  { value: "Federal", label: "Federal" },
  { value: "KPK", label: "KPK" },
  { value: "Sindh", label: "Sindh" },
  { value: "Balochistan", label: "Balochistan" },
];

export const YEARS = [
  { value: "1st year", label: "1st year" },
  { value: "2nd year", label: "2nd year" },
];

export const ACCESS_TYPES = [
  { value: "Allow With Login", label: "Allow With Login" },
  { value: "Allow Without login", label: "Allow Without login (Free)" },
  { value: "Paid", label: "Paid" },
  { value: "Free Trial", label: "Free Trial" },
];

interface UsePastPaperModalProps {
  initialData?: any;
  closeModal: () => void;
  onSubmit?: (data: any) => Promise<void>;
  onSuccess?: (data: any) => void;
}

export const usePastPaperModal = ({
  initialData = null,
  closeModal,
  onSubmit,
  onSuccess,
}: UsePastPaperModalProps) => {
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [data, setData] = useState<any>({
    boardsData: [],
    allTopicsData: [],
    allSubTopicsData: [],
  });
  const [formData, setFormData] = useState<any>({
    name: "",
    boards: [], // Changed from null to empty array for multiple boards
    subject: null,
    province: null,
    year: null,
    access: null,
    topicName: null,
    subTopicName: null,
    thumbnailImageUrl: null,
    pdf: null,
    pdfUrl: null,
    category: "PRE-MEDICAL",
    isFreeForMarketing: false,
    isPublished: true,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getVaultModalDropDownsData");
      // console.log("res", res);
      if (res?.data?.isSuccess) {
        const { topics, boards, subjects, institutes, modules, disciplines } =
          res?.data?.data;
        const newData: Partial<any> = {}; // Use Partial to allow missing properties

        if (institutes?.length > 0) {
          newData.institutesData = institutes?.map((item: any) => ({
            value: item.instituteName,
            label: item.instituteName,
          }));
        }

        if (topics?.length > 0) {
          const topicsData: any[] = topics;
          const allSubTopics = topicsData.flatMap(
            (topic: any) => topic.subTopics || []
          );
          newData.allSubTopicsData = allSubTopics.map((subTopic) => ({
            value: subTopic._id,
            label: subTopic.subTopicName,
          }));

          newData.topicsData = topicsData;
          newData.allTopicsData = topicsData;
          // newData.allSubTopicsData = allSubTopics;
        }

        if (boards?.length > 0) {
          newData.boardsData = boards?.map((item: any) => ({
            value: item.board,
            label: item.board,
          }));
          newData.allBoardsData = newData.boardsData;
        }
        if (subjects?.length > 0) {
          newData.subjectData = subjects?.map((subject: any) => ({
            value: subject.name,
            label: subject.name,
          }));
        }
        if (modules?.length > 0) {
          newData.modulesData = modules?.map((module: any) => ({
            value: module.moduleName,
            label: module.moduleName,
          }));
        }
        if (disciplines?.length > 0) {
          newData.disciplinesData = disciplines?.map((discipline: any) => ({
            value: discipline.disciplineName,
            label: discipline.disciplineName,
          }));
        }

        setData((prevData) => ({ ...prevData, ...newData } as any));
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Populate form data when editing
  useEffect(() => {
    if (initialData) {
      setIsEditMode(true);
      setFormData({
        _id: initialData._id,
        name: initialData.name || "",
        boards: Array.isArray(initialData.boards)
          ? initialData.boards
          : initialData.boards
          ? [initialData.boards]
          : [], // Convert single board to array if needed
        subject: initialData.subject || null,
        province: initialData.province || null,
        year: initialData.year || null,
        access: initialData.access || null,
        topicName: initialData.topicName || null,
        subTopicName: initialData.subTopicName || null,
        thumbnailImageUrl: initialData.thumbnailImageUrl || null,
        pdfUrl: initialData.pdfUrl || null, // Add pdfUrl for existing file
        pdf: null, // Keep pdf null for file input
        category: initialData.category || null,
        isFreeForMarketing: initialData.isFreeForMarketing ?? false,
        isPublished: initialData.isPublished ?? true,
      });

      // Log the initial data for debugging
      // console.log("Initial Data:", initialData);
      // console.log("Form Data Set:", formData);
    }
  }, [initialData]);

  // File upload handler
  const handleFileSelect = (
    type: "thumbnailImageUrl" | "pdf",
    file: File | null
  ) => {
    /* The line `console.log("Form Data Set:", formData);` is logging the current state of the
    `formData` object to the console. This is typically used for debugging purposes to check the
    values of the form data object at a specific point in the code execution. It helps in
    understanding what data is being stored in the form state and can be useful for
    troubleshooting and verifying the correctness of the data being captured from the form inputs
    or initialized with. */
    // console.log("File selected:", type, file);
    setFormData((prev) => ({
      ...prev,
      [type]: file,
    }));
  };

  // Form validation
  const validateForm = () => {
    const fieldLabels: { [key: string]: string } = {
      name: "Name",
      boards: "Board",
      subject: "Subject",
      province: "Province",
      year: "Year",
      access: "Access Type",
      topicName: "Topic",
      // subTopicName: "Sub Topic",
      category: "Category",
    };

    // Check for empty required fields
    const requiredFields = [
      "name",
      "boards",
      "subject",
      "province",
      "year",
      "access",
      "topicName",
      // "subTopicName",
      "category",
    ];

    for (const field of requiredFields) {
      const value = formData[field];
      if (!value || (Array.isArray(value) && value.length === 0)) {
        toast.error(`Please select ${fieldLabels[field]}`);
        return false;
      }
    }

    // Check for files in create mode
    if (!isEditMode) {
      if (!formData.thumbnailImageUrl) {
        toast.error("Please select a thumbnail image");
        return false;
      }

      if (!formData.pdf && !formData.pdfUrl) {
        toast.error("Please select a PDF file");
        return false;
      }
    }

    return true;
  };

  // Form submission handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // console.log("formData: ", formData);

    // Validate form
    if (!validateForm()) {
      return;
    }

    try {
      setLoading(true);

      // Create FormData for file upload
      const formDataToSubmit = new FormData();

      // Add all text fields
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (
          value !== undefined &&
          value !== null &&
          key !== "thumbnailImageUrl" &&
          key !== "pdf" &&
          key !== "_id" // Don't append _id to form data
        ) {
          if (key === "boards" && Array.isArray(value)) {
            // Handle array of boards
            value.forEach((board: string) => {
              formDataToSubmit.append("boards", board);
            });
          } else {
            formDataToSubmit.append(key, String(value));
          }
        }
      });

      // Handle thumbnail image
      if (formData.thumbnailImageUrl) {
        // Case 1: If image is a File object
        if (formData.thumbnailImageUrl instanceof File) {
          formDataToSubmit.append(
            "thumbnailImageUrl",
            formData.thumbnailImageUrl
          );
        }
        // Case 2: If image is a blob URL
        else if (
          typeof formData.thumbnailImageUrl === "string" &&
          formData.thumbnailImageUrl.startsWith("blob:")
        ) {
          const imageBlob = await fetch(formData.thumbnailImageUrl).then((r) =>
            r.blob()
          );
          const imageFile = new File([imageBlob], "thumbnail_image", {
            type: imageBlob.type,
          });
          formDataToSubmit.append("thumbnailImageUrl", imageFile);
        }
      }

      // Handle PDF file
      if (formData.pdf) {
        // Case 1: If PDF is a File object
        if (formData.pdf instanceof File) {
          formDataToSubmit.append("pdf", formData.pdf);
        }
        // Case 2: If PDF is a blob URL
        else if (
          typeof formData.pdf === "string" &&
          formData.pdf.startsWith("blob:")
        ) {
          const pdfBlob = await fetch(formData.pdf).then((r) => r.blob());
          const pdfFile = new File([pdfBlob], "textbook.pdf", {
            type: "application/pdf",
          });
          formDataToSubmit.append("pdf", pdfFile);
        }
      }

      // Custom submit handler takes precedence
      if (onSubmit) {
        await onSubmit(formDataToSubmit);
        return;
      }

      // Default API submission
      let response;
      if (isEditMode && initialData?._id) {
        response = await PastPaperAPI(
          "updatePastPaper",
          [initialData._id],
          formDataToSubmit
        );
      } else {
        response = await PastPaperAPI("addPastPaper", [], formDataToSubmit);
      }

      if (response?.data?.isSuccess) {
        toast.success(
          isEditMode
            ? "Past Paper updated successfully"
            : "Past Paper added successfully"
        );
        closeModal();
        if (onSuccess && response?.data?.data) {
          onSuccess(response.data.data);
        }
      } else {
        toast.error(response?.data?.message || "Operation failed");
      }
    } catch (error) {
      console.error("Error submitting past paper:", error);
      toast.error("An error occurred while submitting past paper");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    data,
    formData,
    setFormData,
    handleFileSelect,
    handleSubmit,
    isEditMode,
  };
};
