import CourseInformation from './components/course-overview';
import { apiRequest } from '(apis)/api-interface/api-handler';
import React, { useContext, useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import SelectFeatures from 'shared-components/select-features';
import Scheduled from 'shared-pages/courses/components/schedule/schedule';
import FAQs from 'shared-pages/courses/components/faqs';
import { ButtonFill, ButtonOutlined } from 'shared-components/ui/CustomButtons';
import { CoursesPricingAPI } from '(apis)/(shared-apis)/courses-pricing';
import Loading from 'shared-components/ui/Loading';
import { Switcher } from 'shared-components/ui/CustomForm';
import AddPricingModal from 'shared-pages/new-courses/components/pricing/pricing-modal';
import SelectTestimonials from 'shared-components/select-testimonials';
import Assignments from 'shared-pages/courses/components/assignments';
import Announcements from 'shared-pages/courses/components/announcements';
import UpcommingClass from 'shared-pages/courses/components/upcomming-class';
import Students from 'shared-pages/courses/components/students';
import { UserContext } from 'state-management/user-context';
import EmailForm from 'shared-pages/courses/components/email';

export default function Main() {
    const { id } = useParams<{ id: string }>();

    const context = useContext(UserContext)
    if (!context) {
      throw new Error('Layout must be used within a UserProvider');
    }
    const { user } = context;


    const HasPerm = (perm: string[]): boolean => {
        if (!user || !user.admin) {
          return false;
        }
        return perm?.some(p =>
          user?.admin?.pagepermissions?.some(pp => pp.toLowerCase() === p.toLowerCase())
        );
      }

    const [isCoursesManager, setHasCoursesManager] = useState(false);
    useEffect(() => {
        setHasCoursesManager(HasPerm(["Course Manager"]));
    }, [user]);
    


    const [selectedTab, setSelectedTab] = useState(["Schedule Management"]);
    const Tabs = [
        "Course Overview",
        "Features Management",
        "Schedule Management",
        "FAQs Management",
        "Pricing Management",
        "Assignments Management",
        "Testimonials Management",
        "Announcements Management",
        "Student Management",
        "Email Management",
        "Add an Upcoming Class",
    ]

    const InternTabs = [
        "Schedule Management",
        "Assignments Management",
        "Announcements Management",
        "Student Management",
        "Email Management",
        "Add an Upcoming Class",
    ]

    const [loading, setLoading] = useState(false);
    const [courseData, setCourseData] = useState(null);
    const [courseFeatureData, setCourseFeatureData] = useState<any[]>([]);

    const getCourseData = async () => {
        try {
            setLoading(true);
            const res = await apiRequest("getCourseById", null, [id]);
            if (res?.status === 200) {
                setCourseData(res.data.course);
                setLoading(false);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message || error.message);
            console.error(error);
            setLoading(false);
        }
    };

    const getAllCourseFeatures = async () => {
        setLoading(true);
        const res = await apiRequest("getFeatures");
        if (res?.status === 200) {
            const formatedData = res?.data?.courseFeatures?.map((item: any) => {
                return {
                    ...item,
                    key: item._id,
                };
            });
            setCourseFeatureData(formatedData);
            setLoading(false);
        }
    };


    useEffect(() => {
        getCourseData();
        getAllCourseFeatures();
    }, []);


 


    return (
        <div className='w-[95%] mx-auto h-full'>
            <h1 className='text-3xl text-gray-700 font-bold'>Course Management</h1>
            <section className="my-5 antialiased flex gap-2 flex-wrap justify-center">
                {isCoursesManager ? Tabs.map((tab, index) => (
                    <div key={index} className={`${selectedTab.toString() === tab ? 'bg-primary text-white' : 'bg-white border border-gray-100 text-gray-700'} cursor-pointer font-bold shadow-2xl px-4 py-2 rounded-md`} onClick={() => setSelectedTab([tab])}>
                        {tab}
                    </div>
                )) : InternTabs.map((tab, index) => (
                    <div key={index} className={`${selectedTab.toString() === tab ? 'bg-primary text-white' : 'bg-white border border-gray-100 text-gray-700'} cursor-pointer font-bold shadow-2xl px-4 py-2 rounded-md`} onClick={() => setSelectedTab([tab])}>
                        {tab}
                    </div>
                ))}
                {/* {isCoursesManager ? "Course Manager":"Course Intern"  } */}
                {selectedTab.includes("Course Overview") && (
                    <CourseInformation
                        id={id}
                        getCourseData={getCourseData}
                        courseData={courseData}
                        loading={loading}
                        setLoading={setLoading}
                    />
                )}

                {selectedTab.includes("Features Management") && (
                    <SelectFeatures
                        featuresData={courseFeatureData}
                        updateApi="updateCourse"
                        isContentTypeFomrData={true}
                        data={courseData}
                        fetchData={getCourseData}
                        loading={loading}
                    />
                )}
    
                {selectedTab.includes("Schedule Management") && (
                    <Scheduled
                        id={id}
                    />
                )}

                {selectedTab.includes("FAQs Management") && (
                    <FAQs
                        id={id}
                        courseData={courseData}
                        getCourseData={getCourseData}
                        loading={loading}
                        setLoading={setLoading}
                    />
                )}

                {selectedTab.includes("Pricing Management") && (
                    <PricingManagement
                        id={id}
                    />
                )}

                {selectedTab.includes("Testimonials Management") && (
                    <SelectTestimonials
                        updateSelectedTestimonialsApi={"updateCourse"}
                        id={id}
                        isMultiPartFormData={true}
                        getData={getCourseData}
                        loading={loading}
                        storedSelectedTestimonials={courseData?.selectedTestimonials}
                    />
                )}

                {selectedTab.includes("Student Management") && (
                    <Students
                        id={id}
                        courseData={courseData}
                        setCoursedata={setCourseData}
                        user={user}
                    />
                )}

                {selectedTab.includes("Assignments Management") && (
                    <Assignments
                        id={id}
                        loading={loading}
                        setLoading={setLoading}
                        access={"superadmin"}
                    />
                )}

                {selectedTab.includes("Announcements Management") && (
                    <Announcements/>
                )}

                {selectedTab.includes("Email Management") && (
                    <EmailForm  courseData={courseData}/>
                    // <div className='w-full h-full flex justify-center items-center mt-10'>
                    //     <h1 className='text-3xl text-gray-700 font-bold'>Coming Soon</h1>
                    // </div> 
                )}

                {selectedTab.includes("Add an Upcoming Class") && (
                    <UpcommingClass
                        courseId={id}
                        getCourseData={getCourseData}
                        courseData={courseData}
                    />
                )}


                <Toaster />
            </section>
        </div>
    )
}


const PricingManagement = ({ id }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pricingData, setPricingData] = useState([]);
    const [selectedSKU, setSelectedSKU] = useState(null);

    const getPricingData = async () => {
        setLoading(true);
        const res = await CoursesPricingAPI("getCoursePricing", [id]);
        if (res?.data.statusCode === 200) {
            setPricingData(res.data.data);
        }
        else {
            toast.error(res?.data?.message || "Failed to fetch pricing data");
        }
        setLoading(false);
    }

    useEffect(() => {
        getPricingData();
    }, []);

    const formatDate = (date: string) => {
        return new Date(date).toLocaleDateString();
    }

    const [mode, setMode] = useState('add');
    const handleEdit = (item: any) => {
        setOpen(true);
        setSelectedSKU(item);
        setMode('edit');
    }

    const handleStatus = async (item: any, value: boolean) => {
        const res = await CoursesPricingAPI("handleCoursePricingStatus", [item._id]);
        if (res?.data.statusCode === 200) {
            toast.success("Pricing Plan Status Updated Successfully");
            getPricingData();
        }
        else {
            toast.error(res?.data?.message || "Failed to update pricing plan status");
        }
    }

    return (
        <div className='w-[95%] mx-auto h-full mt-10'>
            <div className='flex justify-between items-center'>
                <h1 className='text-3xl text-gray-700 font-bold'>Pricing Management</h1>
                <ButtonFill
                    handleClick={() => {
                        setOpen(true);
                        setMode('add');
                    }}
                >
                    Add Pricing
                </ButtonFill>
            </div>

            {loading ? (
                <div className='flex justify-center items-center h-full'>
                    <Loading />
                </div>
            ) : (
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-start'>
                    {pricingData && pricingData.map((item: any) => (
                        <div key={item._id} className='bg-white border border-gray-100 p-4 rounded-md space-y-2'>
                            <h1 className='text-xl text-gray-700 font-bold'>{item?.SKUName}</h1>
                            <p className='text-gray-500'>{item?.Tag}</p>
                            <p className='text-gray-500'>{item?.PreMedAccess && item?.PreMedAccess?.length > 0 ?
                                <div className='flex flex-wrap gap-2'>
                                    {item?.PreMedAccess?.map((access: any) => (
                                        <span key={access} className='bg-gray-100 text-gray-500 px-2 text-xs py-1 rounded-md'>{access}</span>
                                    ))}
                                </div> : "No Pre-Med Access"}</p>
                            <p className='text-gray-500'>Price: {item?.Price}</p>
                            <p className='text-gray-500'>Discount: {item?.Discount}</p>
                            <div className='flex flex-wrap gap-2'>
                                <span className='text-gray-500'>Subscription Ends: </span>
                                {!item?.endDate ? (
                                    <p className='text-gray-500'>{item?.numberOfMonths} Months</p>
                                ) : (
                                    <p className='text-gray-500'>{formatDate(item?.endDate)}</p>
                                )}
                            </div>
                            <div className='flex gap-2'>
                                <ButtonOutlined
                                    handleClick={() => handleEdit(item)}
                                >
                                    Edit
                                </ButtonOutlined>
                                <Switcher
                                    for={`isPublished-${item._id}`}
                                    isActive={item?.isPublished}
                                    togglevalue={item?.isPublished}
                                    onChange={(value) => handleStatus(item, value)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    ))}


                </div>
            )}

            <AddPricingModal
                active={open}
                setModal={setOpen}
                courseId={id}
                mode={mode as 'add' | 'edit'}
                selectedSKU={selectedSKU}
                setSelectedSKU={setSelectedSKU}
                fetchPricingData={getPricingData}
                setMode={setMode}
                type="old-courses"
            />
        </div>
    )
}

