
import { EditIcon, TickFillIcon } from "shared-components/ui/Icons";
import CustomBadges from "shared-components/ui/CustomBadges";
interface PlanId {
    PlanID: string;
}

interface CourseId {
    courseIds: string;
}

export const TableView = ({ orders, skus, courses , selectedCategory}) => {

    const GetDate = (date: string) => {
        //dd-mm-yyyy
        const d = new Date(date);
        return `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
    };

    const getPlanName = (plansIds: [PlanId]) => {
        const planNames = [];
        plansIds.forEach((planId) => {
            if (planId.PlanID) {
                const plan = skus.find((sku) => sku._id === planId.PlanID);
                planNames.push(plan?.SKUName || "");
            }
        });
        return planNames.join(", ");
    };

    const getCourseName = (courseId: [CourseId]) => {
        const courses = [];
        courseId.forEach((courseId) => {
            if (courseId.courseIds) {
                const course = courses.find(
                    (course) => course._id === courseId.courseIds
                );
                courses.push(course?.CourseName || "");
            }
        });
        return courses.join(", ");
    };

    const GetTime = (date = new Date()) => {
        const d = new Date(date);
        return d.toLocaleTimeString();
    };



    return (
        <div className="overflow-x-auto">
            <table className="w-full mb-20 text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th scope="col" className="px-4 py-4">
                            Date of Order
                        </th>
                        <th scope="col" className="px-4 py-4">
                            Time of Order
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Customer Name
                        </th>
                        <th scope="col" className="px-4 py-4 text-left">
                            Coupon Used
                        </th>
                        <th scope="col" className="px-4 py-4 text-center">
                            Products
                        </th>
                        <th scope="col" className="px-4 py-3 text-center">
                            Order Status
                        </th>
                        <th scope="col" className="px-4 py-3 text-center">
                            isAddedToBank
                        </th>
                        <th scope="col" className="px-4 py-3 text-center">
                            Paid Amount
                        </th>
                        <th scope="col" className="px-4 py-3 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr className="border-b" key={order._id}>
                            <td className="px-4 py-3">
                                {GetDate(order.CreationDate)}
                            </td>
                            <td className="px-4 py-3">
                                {GetTime(order.CreationDate)}
                            </td>
                            <td className="px-4 py-3">{order.UserName}</td>
                            <td className="px-4 py-3">{order.CouponCode}</td>
                            <td className="px-4 py-3 text-center">
                                <div className="flex flex-row gap-1 flex-wrap justify-center">
                                {order?.PRODUCTS?.join(",")}
                                {order?.type?.includes("UPGRADE") && (
                                    <CustomBadges
                                        label="UPGRADE"
                                        type="warning"
                                    />
                                )}
                                {order?.type?.includes("RECOMMAND") && (
                                    <CustomBadges
                                        label="RECOMMENDED"
                                        type="info"
                                    />
                                    )}
                                </div>
                            </td>
                            <td className={`px-4 py-3 text-center`}>
                                {order.OrderStatus === "Pending" && (
                                    <CustomBadges
                                        label={order.OrderStatus}
                                        type="warning"
                                    />
                                )}
                                {order.OrderStatus === "Accepted" && (
                                    <CustomBadges
                                        label={order.OrderStatus}
                                        type="success"
                                    />
                                )}
                                {order.OrderStatus === "Denied" && (
                                    <CustomBadges
                                        label={order.OrderStatus}
                                        type="light"
                                    />
                                )}
                            </td>
                            <td className="px-4 py-3 text-center flex items-center justify-center">
                                {order?.isAddedToBank ? (
                                    <TickFillIcon size="w-5 h-5" />
                                ) : null}
                            </td>
                            <td className="px-4 py-3 text-center">
                                {order.PaidAmount ? order.PaidAmount : 0}
                            </td>
                            <td className="px-4 py-3 text-center">
                                <button
                                    onClick={() => {
                                        window.location.href = `/verifyorder/${selectedCategory}/${order._id}`;
                                    }}
                                >
                                    <EditIcon color="text-black" size="w-6 h-6" />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>

    );
}