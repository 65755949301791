// Define types for the items in the arrays
interface SelectOption {
  value: string;
  label: string;
}

// Entity values
const entityValues: SelectOption[] = [
  { value: "HIGH SCHOOL", label: "HIGH SCHOOL" },
  { value: "ENTRANCE EXAM", label: "ENTRANCE EXAM" },
  { value: "UNDERGRADUATE", label: "UNDERGRADUATE" },
];

// Access data
const accessData: SelectOption[] = [
  { value: "Allow With Login", label: "Allow With Login" },
  { value: "Allow Without login", label: "Allow Without login (Free)" },
  { value: "Paid", label: "Paid" },
  { value: "Free Trial", label: "Free Trial" },

];

// Category values
const categoryValues: SelectOption[] = [
  { value: "PRE-ENGINEERING", label: "PRE-ENGINEERING" },
  { value: "PRE-MEDICAL", label: "PRE-MEDICAL" },
  { value: "INTERNATIONAL", label: "INTERNATIONAL" },
  { value: "BUSINESS-MANAGMENT", label: "BUSINESS-MANAGMENT" },
  { value: "INTERMEDIATE", label: "INTERMEDIATE" },
  { value: "CAMBRIDGE", label: "CAMBRIDGE" },
  { value: "MEDSCHOOL", label: "MEDSCHOOL" },
  { value: "ALLIED-HEALTH-SCIENCES", label: "ALLIED HEALTH SCIENCES" },
  { value: "FSC", label: "FSC" },
];

// Province data
const provinceData: SelectOption[] = [
  // { value: "", label: "unselect" },
  { value: "Sindh", label: "Sindh" },
  { value: "Punjab", label: "Punjab" },
  { value: "Balochistan", label: "Balochistan" },
  { value: "Khyber Pakhtunkhwa", label: "Khyber Pakhtunkhwa" },
  { value: "Azad Jammu and Kashmir", label: "Azad Jammu and Kashmir" },
  { value: "Gilgit-Baltistan", label: "Gilgit-Baltistan" },
  { value: "Federal", label: "Federal" },
];

// University year data
const uniYearData: SelectOption[] = [
  { value: "FIRST_YEAR", label: "FIRST_YEAR" },
  { value: "SECOND_YEAR", label: "SECOND_YEAR" },
  { value: "THIRD_YEAR", label: "THIRD_YEAR" },
  { value: "FOURTH_YEAR", label: "FOURTH_YEAR" },
  { value: "FIFTH_YEAR", label: "FIFTH_YEAR" },
];
const tag = [
  { label: "Popular", value: "Popular" },
  { label: "Best Value", value: "Best Value" },
  { label: "Exclusive", value: "Exclusive" },
  { label: "Limited Time Offer", value: "Limited Time Offer" },
  { label: "New Arrival", value: "New Arrival" },
  { label: "Top Rated", value: "Top Rated" },
  { label: "Customizable", value: "Customizable" },
  { label: "Starter Kit", value: "Starter Kit" },
  { label: "Premium", value: "Premium" },
  { label: "Value Pack", value: "Value Pack" },
];
const modulesReactQuill = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { align: "center" },
      { align: "right" },
      { align: "justify" },
      { align: "left" },
    ],
    [{ color: [] }, { background: [] }],
  ],
};
const myBundlefeatureTypeValues = [
  { value: "QUESTIONS", label: "QUESTIONS" },
  { value: "VAULT", label: "VAULT" },
  { value: "GUIDANCE", label: "GUIDANCE" },
  { value: "LECTURES", label: "LECTURES" },
];

const logsOfListPionts = [
  {
    label: "Red",
    value:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/teacherImage/0407668a-ed94-40a3-8bd6-a188ab66efb1.png",
  },
  {
    label: "Green",
    value:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/teacherImage/c808e361-c5e0-4073-9ab1-03dd5527486d.png",
  },
  {
    label: "Yellow",
    value:
      "https://premedpk-cdn.sgp1.digitaloceanspaces.com/teacherImage/bc93f1dc-020a-46b8-99d0-ee1a34f72c6e.png",
  },
];

const Categories = [
  "PRE-MEDICAL",
  "PRE-ENGINEERING",
  "INTERNATIONAL",
  "MEDSCHOOL",
  "ALLIED-HEALTH-SCIENCES",
]

const AcademicRoutes = [
  { name: "Manage Categories", path: '/manage/categories', permissions: ['Category Access', "Deck Manager"]  },
  { name: "Add Questions", path: '/addquestion', permissions: ['Add Question'] },
  { name: "Manage Topics", path: '/manage/topics', permissions: ['Topic Access'] },
  { name: "Modules", path: '/manage/modules', permissions: ['Module Access'] },
  { name: "Disciplines", path: '/manage/disciplines', permissions: ['Discipline Access'] },
  { name: "References", path: '/manage/references', permissions: ['Reference Access'] }, 
  { name: "Institutions", path: '/manage/institutions', permissions: ['Institution Access'] },
  { name: "Filter Questions", path: '/filter-questions', permissions: ['Filter Question'] },
  { name: "Question Reports", path: '/manage/audit-questions-reports', permissions: ['Question Reports'] },
]


const FinanceRoutes = [
  { name: "Manage Accounts", path: '/manage/finances', permissions: ['Payments Management'] },
  { name: "Manage Orders", path: '/oms', permissions: ['Order Access'] },
  { name: "Order Statistics", path: '/oms-manager', permissions: ['Order Statistics'] },
  { name: "Payees Management", path: '/manage/employee-provider-finance', permissions: ['Payments Management'] },
  { name: "Payments Management", path: '/manage/payments', permissions: ['Payments Management'] },
  { name: "Revenue", path: '/revenue', permissions: ['Revenue Access'] },
  { name: "Course Revenue", path: '/manage/courses', permissions: ['Course Access'] },
//  { name: "Old Revenue Data", path: '/old-revenue-data', permissions: ['Revenue Access'] },
]

const VaultRoutes = [
  { name: "Manage Notes", path: '/manage/notes', permissions: ['Note Access'] },
  { name: "Manage Shortlistings", path: '/manage/shortlistings', permissions: ['Shortlisting Access'] },
  { name: "Manage Mnemonics", path: '/manage/mnemonics', permissions: ['Mnemonic Access'] },
  { name: "Manage Cheatsheets", path: '/manage/cheatsheets', permissions: ['Cheatsheet Access'] },
  { name: "Manage Essentials", path: '/manage/essentials', permissions: ['Essentials Access'] },
  { name: "Manage SnapCourses", path: '/manage/snapcourses', permissions: ['SnapCourse Access'] },
  { name: "Manage Topical Guides", path: '/manage/guides', permissions: ['Guide Access'] },
]

const ExpertSolution = [
  { name: "PreMed Experts", path: '/experts', permissions: ['Expert Access'] },
  { name: "Expert Admin", path: '/adminmanager', permissions: ['Admin Expert Access'] },
  { name: "Manage Experts", path: '/manage-experts', permissions: ['Admin Expert Access'] },
]

const Plans = [
  { name: "Create Plans", path: '/manage/plans', permissions: ['Plan Access'] },
  { name: "MOB Plans", path: '/manage/mybundles', permissions: ['Plan Access'] },
]

const Marketing = [
  { name: "Conversions", path: '/manage/conversions', permissions: ['Conversion Access'] },
  { name: "Marketing Download", path: '/marketing-download', permissions: ['Marketing Access'] },
  { name: "Deck Statistics", path: '/deck/statistics', permissions: ['Marketing Access'] },
  { name: "Send Notification", path: '/send-notification', permissions: ['Marketing Access'] },
  { name: "Statistics", path: '/view-Statistics', permissions: ['Marketing Access'] },
  { name: "Team", path: '/manage/team', permissions: ['Teams Page Access'] },
  { name: "Department", path: '/manage/department', permissions: ['Teams Page Access'] },
  { name: "Testimonials", path: '/manage/testimonials', permissions: ['Testimonials Page Access'] },
  { name: "Free Trial", path: '/manage/free-trial', permissions: ['Free Trial Access'] },

]

const imageSize = 100
const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 
  "image/svg+xml"];

export {
  imageSize, validImageTypes,
  entityValues,
  tag,
  categoryValues,
  accessData,
  provinceData,
  uniYearData,
  modulesReactQuill,
  myBundlefeatureTypeValues,
  logsOfListPionts,
  Categories,
  AcademicRoutes,
  FinanceRoutes,
  VaultRoutes,
  ExpertSolution,
  Plans,
  Marketing,
};
