//@typescript-eslint/no-unused-expressions
import { apiHandler, apiRequest } from '(apis)/api-interface/api-handler';
import React, { useContext, useEffect, useState, useMemo } from 'react'
import { Select } from 'antd';
import CustomDropDown from 'shared-components/ui/CustomDropDown';
import { categoryValues } from 'constants/constantvalues';
import { X } from '@mui/icons-material';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import toast, { Toaster } from 'react-hot-toast';
import { UserContext } from 'state-management/user-context';
import { DeleteIcon, EditIcon, EyeIcon } from 'shared-components/ui/Icons';

export const TaxPaymentModal = ({
    ShowModel,
    setShowModel,
    isEditMode,
    selectedTaxPayment,
    fetchTaxPayments,
    setModalType
}: {
    ShowModel: boolean,
    setShowModel: (value: boolean) => void,
    isEditMode: boolean,
    selectedTaxPayment: any,
    fetchTaxPayments: () => void,
    setModalType: (value: string) => void
}) => {

    const [departments, setDepartments] = useState([]);
    const { user } = useContext(UserContext);
    const [serviceProviders, setServiceProviders] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const GetServiceProviders = async () => {
        const res = await apiRequest("getServiceProviders");
        if (res.data?.isSuccess) {
            setServiceProviders(res.data?.serviceProviders);
        }
        const res2 = await apiRequest("getBankAccounts");
        if (res2.data?.isSuccess) {
            setBankAccounts(res2.data?.bankAccounts);
            setDepartments(res2.data?.departments);
        }
    };

    useEffect(() => {
        GetServiceProviders();
    }, []);



    interface BreakDownAmounts {
        category: string;
        amount_before_tax: number;
        // amount_after_tax: number;
        paid_amount: number;
        tax_amount: number;
        tax_rate: string;
        department: string;
        tags: string
    }

    const [formData, setFormData] = useState<{
        tax_type: string;
        vendor_name: string;
        paidByOurAccounts: string[];
        service_provider: string;
        breakdownAmounts: BreakDownAmounts[];
        addedBy: string;
        description: string;
        modifiedBy: string;
        screenshot: string;
        payment_date: Date;
        createdAt: Date;
    }>({
        tax_type: "",
        vendor_name: "",
        paidByOurAccounts: [],
        service_provider: "",
        breakdownAmounts: [],
        description: "",
        addedBy: "",
        modifiedBy: "",
        screenshot: "",
        payment_date: new Date(),
        createdAt: new Date()
    })

    useEffect(() => {
        if (selectedTaxPayment && isEditMode) {
            setFormData({
                tax_type: selectedTaxPayment?.tax_type,
                vendor_name: selectedTaxPayment?.vendor_name,
                paidByOurAccounts: selectedTaxPayment?.paidByOurAccounts,
                service_provider: selectedTaxPayment?.service_provider,
                breakdownAmounts: selectedTaxPayment?.breakdownAmounts.map((item) => ({
                    category: item.category,
                    amount_before_tax: item.amount_before_tax,
                    // amount_after_tax: item.amount_after_tax,
                    paid_amount: item.paid_amount,
                    tax_amount: item.tax_amount,
                    tax_rate: item.tax_rate,
                    department: item.department,
                    tags: item.tags
                })),
                createdAt: new Date(selectedTaxPayment?.createdAt || new Date()),
                addedBy: selectedTaxPayment?.addedBy,
                modifiedBy: selectedTaxPayment?.modifiedBy,
                screenshot: selectedTaxPayment?.screenshot,
                description: selectedTaxPayment?.description,
                payment_date: new Date(selectedTaxPayment?.payment_date || new Date())
            });
        }
    }, [selectedTaxPayment, isEditMode]);


    const handleSubmit = async () => {
        try {
            //if no screenshot is uploaded then make it empty
            if (!formData.screenshot && selectedTaxPayment?.screenshot && selectedTaxPayment?.screenshot !== "" && isEditMode) {
                formData.screenshot = selectedTaxPayment?.screenshot;
            }

            //validate form data
            if (formData.breakdownAmounts.length === 0) {
                toast.error("Please add at least one breakdown amount");
                return;
            }

            //make sure all breakdown amounts are numbers and not negative
            for (const item of formData.breakdownAmounts) {
                if (isNaN(Number(item.amount_before_tax)) || Number(item.amount_before_tax) < 0 || Number(item.paid_amount) < 0 || Number(item.tax_amount) < 0) {
                    toast.error("Please enter valid numbers for breakdown amounts");
                    return;
                }
            }

            //iterate through breakdown amounts and make sure every field is filled
            for (const item of formData.breakdownAmounts) {
                if (!item.category || !item.amount_before_tax || !item.tax_rate || !item.paid_amount) {
                    toast.error("Please fill all fields for category, amount before tax, tax rate, and paid amount");
                    return;
                }
            }

            //make sure tax_type is not empty
            if (!formData.tax_type) {
                toast.error("Please select a tax type");
                return;
            }

            //make sure total amount is not negative
            if (formData.breakdownAmounts.reduce((sum, item) => sum + Number(item.tax_amount), 0) < 0) {
                toast.error("Total amount cannot be negative");
                return;
            }

            //make sure total amount is not zero
            if (formData.breakdownAmounts.reduce((sum, item) => sum + Number(item.tax_amount), 0) === 0) {
                toast.error("Total amount cannot be zero");
                return;
            }

            //make sure user is logged in
            if (!user?.username) {
                toast.error("Please login to continue");
                return;
            }

            //make sure screenshot is not empty
            if (!formData.screenshot) {
                toast.error("Please upload a screenshot");
                return;
            }

            //make sure payment_date is not in the future
            if (formData.payment_date > new Date()) {
                toast.error("Payment date cannot be in the future");
                return;
            }

            // Check if in edit mode and no new screenshot is uploaded
            if (isEditMode && !formData.screenshot) {
                formData.screenshot = selectedTaxPayment?.screenshot; // Retain the existing screenshot
            }

            const payload = {
                ...formData,
                totalAmount: formData.breakdownAmounts.reduce((sum, item) =>
                    sum + Number(item.paid_amount), 0),
                addedBy: isEditMode ? selectedTaxPayment?.addedBy : user?.username,
                modifiedBy: isEditMode ? selectedTaxPayment?.modifiedBy : user?.username
            };

            const res = await apiHandler({
                data: payload,
                name: isEditMode ? "updateTaxReturns" : "addTaxReturns",
                params: isEditMode ? [`${selectedTaxPayment?._id}`] : []
            });

            if (res.data?.isSuccess) {
                toast.success("Payment submitted successfully");
                fetchTaxPayments();
                setShowModel(false);
                setModalType("add");
                // Reset form   
                setFormData({
                    tax_type: "",
                    breakdownAmounts: [],
                    vendor_name: "",
                    service_provider: "",
                    addedBy: "",
                    modifiedBy: "",
                    createdAt: new Date(),
                    paidByOurAccounts: [],
                    screenshot: "",
                    description: "",
                    payment_date: new Date()
                });
                window.location.reload();
            }
        } catch (error) {
            console.error("Payment submission error:", error);
            toast.error("Payment submission error");
        }
    };

    const convertToBase64 = async (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-4xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            {isEditMode ? "Update" : "Add New"} Revenue
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setFormData({
                                    tax_type: "",
                                    breakdownAmounts: [],
                                    vendor_name: "",
                                    service_provider: "",
                                    addedBy: "",
                                    modifiedBy: "",
                                    createdAt: new Date(),
                                    paidByOurAccounts: [],
                                    screenshot: "",
                                    description: "",
                                    payment_date: new Date()
                                })
                                setShowModel(false);
                                setModalType("add");
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/*SELECT EMPLOYEE*/}
                    <div className='flex flex-col gap-2'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                            <div className='flex justify-between gap-2'>
                                <div className='flex flex-col gap-2 w-full'>
                                    <label htmlFor="taxTypeId">Tax Type</label>
                                    <input type="text" placeholder="Tax Type"
                                        className="border p-2 rounded w-full bg-transparent"
                                        value={formData.tax_type}
                                        onChange={(e) => setFormData({ ...formData, tax_type: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div >
                                <label className="block mb-2">Paid By Our AccRevenueounts</label>
                                <Select
                                    placeholder="Select paid by our accounts"
                                    options={bankAccounts.map((bankAccount) => ({
                                        label: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                        value: `${bankAccount?.holderName} - ${bankAccount?.accountNumber} - ${bankAccount?.bankName}`,
                                    }))}
                                    onChange={(value) => {
                                        setFormData({ ...formData, paidByOurAccounts: value });
                                    }}
                                    className="w-full h-10 border border-gray-300 font-rubik"
                                    bordered={false}
                                    value={formData.paidByOurAccounts}
                                    mode="multiple"
                                />
                            </div>



                            <div className='flex flex-col gap-2'>
                                <label htmlFor="vendor_name">Vendor Name</label>
                                <input type="text" placeholder="Vendor Name" className="border p-2 rounded w-full bg-transparent" value={formData.vendor_name} onChange={(e) => setFormData({ ...formData, vendor_name: e.target.value })} />
                            </div>

                            <div className="flex flex-col gap-2 w-full">
                                <label htmlFor="serviceProviderId">Service Provider</label>
                                <Select
                                    disabled={isEditMode}
                                    showSearch
                                    placeholder="Select a service provider"
                                    options={serviceProviders.map((serviceProvider) => ({
                                        label: serviceProvider.name,
                                        value: serviceProvider._id,
                                    }))}
                                    onChange={(value) => {
                                        setFormData({ ...formData, service_provider: value });
                                    }}
                                    className="w-full h-10 border border-gray-300 font-rubik"
                                    bordered={false}
                                    value={formData.service_provider}
                                    filterOption={(input, option) =>
                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                />
                            </div>

                        </div>

                        {/* Breakdown Amounts Section */}
                        <div className="mt-4">
                            <label className="block mb-2">Payment Breakdown</label>
                            {formData.breakdownAmounts.map((item, index) => (
                                <div key={index} className="grid grid-cols-2 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg">
                                    <CustomDropDown
                                        options={categoryValues.map((category) => category.value)}
                                        onSelect={(value) => {
                                            const newBreakdown = [...formData.breakdownAmounts];
                                            newBreakdown[index].category = value;
                                            setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                        }}
                                        value={item.category}
                                        label="Category"
                                        width="w-full"
                                    />
                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="amount_before_tax">Amount Before Tax</label>
                                        <input
                                            type="number"
                                            placeholder="Amount Before Tax"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.amount_before_tax || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].amount_before_tax = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="paid_amount">Paid Amount</label>
                                        <input
                                            type="number"
                                            placeholder="Paid Amount"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.paid_amount || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].paid_amount = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="tax_amount">Tax Amount</label>
                                        <input
                                            type="number"
                                            placeholder="Tax Amount"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.tax_amount || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].tax_amount = e.target.value === '' ? 0 : Number(e.target.value);
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="tax_rate">Conversion Rate</label>
                                        <input
                                            type="number"
                                            placeholder="Conversion Rate"
                                            className="border p-2 rounded w-full bg-transparent"
                                            value={item.tax_rate || ''}
                                            onChange={(e) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].tax_rate = e.target.value === '' ? '' : e.target.value;
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <CustomDropDown
                                            options={departments.map((department) => department.name)}
                                            onSelect={(value) => {
                                                const newBreakdown = [...formData.breakdownAmounts];
                                                newBreakdown[index].department = value;
                                                setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                            }}
                                            label="Department"
                                            width="w-full"
                                            value={item.department}
                                        />
                                    </div>

                                    <div className='flex flex-col gap-2'>
                                        <label htmlFor="tags">Tags</label>
                                        <input type="text" placeholder="Tags" className="border p-2 rounded w-full bg-transparent" value={item.tags || ''} onChange={(e) => {
                                            const newBreakdown = [...formData.breakdownAmounts];
                                            newBreakdown[index].tags = e.target.value;
                                            setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                        }} />
                                    </div>

                                    <X onClick={() => {
                                        const newBreakdown = formData.breakdownAmounts.filter((_, i) => i !== index);
                                        setFormData({ ...formData, breakdownAmounts: newBreakdown });
                                    }}
                                        className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                                    />
                                </div>
                            ))}
                            <ButtonFill
                                handleClick={() => {
                                    setFormData({
                                        ...formData,
                                        breakdownAmounts: [...formData.breakdownAmounts, {
                                            category: '',
                                            amount_before_tax: 0,
                                            paid_amount: 0,
                                            tax_amount: 0,
                                            tax_rate: '',
                                            department: '',
                                            tags: ''
                                        }]
                                    });
                                }}
                            >
                                Add Breakdown
                            </ButtonFill>
                        </div>

                        {/* Added By Section */}

                        {/* Created At Section */}
                        <div className="flex flex-row gap-2">
                            <div className="mt-4">
                                <label className="block mb-2">Expense Date</label>
                                <input type="date" placeholder="Created At" className="border p-2 rounded" value={formData.createdAt.toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, createdAt: new Date(e.target.value) })} />
                            </div>

                            <div className="mt-4">
                                <label className="block mb-2">Payment Date</label>
                                <input type="date" placeholder="Payment Date" className="border p-2 rounded" value={formData.payment_date.toISOString().split('T')[0]} onChange={(e) => setFormData({ ...formData, payment_date: new Date(e.target.value) })} />
                            </div>
                            <div className="mt-4">
                                <label className="block mb-2">Screenshot</label>
                                <input type="file" className="border p-2 rounded"
                                    accept="image/png, image/jpeg"
                                    onChange={async (e) => {
                                        //upload in base64  
                                        const file = e.target.files?.[0];
                                        if (file) {
                                            const base64 = await convertToBase64(file);
                                            setFormData({ ...formData, screenshot: base64 as string });
                                        }
                                    }} />
                                {formData.screenshot && <img src={formData.screenshot} alt="Screenshot" className="w-24 h-24 object-fit border mt-2 rounded" />}
                            </div>
                        </div>



                        {/* Description Section */}
                        <div className="mt-4 w-full">
                            <label className="block mb-2">Description</label>
                            <input type="text" placeholder="Description" className="border p-2 rounded w-full" value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                        </div>

                        {/* Screenshot Section */}


                        {/* Submit Button */}
                        <div className="mt-4 flex justify-end">
                            <ButtonFill
                                handleClick={handleSubmit}
                                disabled={!formData.tax_type}
                            >
                                {isEditMode ? 'Update' : 'Submit'} Tax Payment
                            </ButtonFill>
                        </div>
                        <Toaster />
                    </div>

                </div>
            </div>
        </div>
    )
}


export const ViewTaxPaymentModal = ({ ShowModel, setShowModel, selectedTaxPayment }: { ShowModel: boolean, setShowModel: (value: boolean) => void, selectedTaxPayment: any }) => {

    const GetDate = (date: Date) => {
        return new Date(date).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }

    // Get service provider list and find the name
    const [serviceProvidersList, setServiceProvidersList] = useState([]);

    const getServiceProviderName = (id: string) => {
        const provider = serviceProvidersList.find(provider => provider._id === id);
        return provider ? provider.name : id;
    }

    const GetServiceProviders = async () => {
        try {
            const res = await apiRequest("getServiceProviders");
            if (res.data?.isSuccess) {
                setServiceProvidersList(res.data?.serviceProviders);
            }
        } catch (error) {
            console.error("Error fetching service providers:", error);
        }
    };

    useEffect(() => {
        GetServiceProviders();
    }, []);

    return (
        <div
            id="viewPaymentModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-6xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            View Revenue
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                setShowModel(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {/* TODO: Add Payment Details */}
                    <div className='flex flex-col gap-4'>
                        <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                            <div className='flex flex-row gap-2'>
                                <b>Tax Type:</b> {selectedTaxPayment?.tax_type}
                            </div>
                            <div className='flex flex-row gap-2'>
                                <b>Vendor Name:</b> {selectedTaxPayment?.vendor_name}
                            </div>
                            <div className='flex flex-row gap-2'>
                                <b>Service Provider:</b> {selectedTaxPayment?.service_provider ? getServiceProviderName(selectedTaxPayment?.service_provider) : "N/A"}
                            </div>
                            <div className='flex flex-row gap-2'>
                                <b>Amount Paid:</b> {selectedTaxPayment?.totalAmount}
                            </div>
                            <div className='flex flex-row gap-2'>
                                <b>Date of Tax Payment:</b> {GetDate(selectedTaxPayment?.createdAt)}
                            </div>
                            <div className='flex flex-row gap-2'>
                                <b>Payment Date:</b> {GetDate(selectedTaxPayment?.payment_date)}
                            </div>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <b>Breakdown Amounts:</b>
                            <div className='flex flex-row gap-2 w-full'>
                                <table className='w-full'>
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-4 py-4">
                                                No.
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Category
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Amount Before Tax
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Description
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Paid Amount
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Tax Amount
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Conversion Rate
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Department
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Tags
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedTaxPayment?.breakdownAmounts.map((item: any, index: number) =>
                                            <tr key={index} className="border-b">
                                                <td className="px-4 py-2 text-center">{index + 1}</td>
                                                <td className="px-4 py-2 text-center">{item?.category}</td>
                                                <td className="px-4 py-2 text-center">{item?.amount_before_tax}</td>
                                                <td className="px-4 py-2 text-center">{item?.description}</td>
                                                <td className="px-4 py-2 text-center">{item?.paid_amount}</td>
                                                <td className="px-4 py-2 text-center">{item?.tax_amount}</td>
                                                <td className="px-4 py-2 text-center">{item?.tax_rate}%</td>
                                                <td className="px-4 py-2 text-center">{item?.department}</td>
                                                <td className="px-4 py-2 text-center">{item?.tags}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {/* Description Section */}
                        <div className="mt-4 w-full">
                            <label className="block mb-2">Description</label>
                            <div className="border p-2 rounded w-full">
                                {selectedTaxPayment?.description}
                            </div>
                        </div>

                        <div className='flex flex-row gap-2 justify-between'>
                            <div className='flex justify-start gap-2'><b>Added By:</b> {selectedTaxPayment?.addedBy}</div>
                            <div className='flex justify-end gap-2'><b>Expense Date:</b> {GetDate(selectedTaxPayment?.createdAt)}</div>
                            <div className='flex justify-end gap-2'><b>Payment Date:</b> {GetDate(selectedTaxPayment?.payment_date)}</div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    )
}

export const TaxPaymentTable = (
    {
        taxPayments,
        setOpenAddTaxPaymentModal,
        setModalType,
        setSelectedTaxPayment,
        setOpenViewTaxPaymentModal,
        showEdit = true,
        GetDate,
        fetchTaxPayments
    }: {
        taxPayments: any[],
        setOpenAddTaxPaymentModal: (value: boolean) => void,
        setModalType: (value: string) => void,
        setSelectedTaxPayment: (value: any) => void,
        setOpenViewTaxPaymentModal: (value: boolean) => void,
        GetDate: (date: string) => string,
        fetchTaxPayments: () => void,
        showEdit?: boolean
    }) => {

        const DeleteTaxPayment = async (id: string) => {
            try {
                const response = await apiHandler({
                    name: "deleteTaxReturns",
                    params: [id]
                })
                if (response.data.isSuccess) {
                    fetchTaxPayments();
                    toast.success(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to delete internal payment");
                console.error(error);
            }
        }


        const [searchText, setSearchText] = useState('');
        const [filteredData, setFilteredData] = useState(taxPayments);
        const [currentPage, setCurrentPage] = useState(1);
        const [serviceProvidersList, setServiceProvidersList] = useState([]);

        // New filter states
        const [expenseStartDate, setExpenseStartDate] = useState("");
        const [expenseEndDate, setExpenseEndDate] = useState("");
        const [paymentStartDate, setPaymentStartDate] = useState("");
        const [paymentEndDate, setPaymentEndDate] = useState("");
        const [taxType, setTaxType] = useState("");
        const [vendorName, setVendorName] = useState("");
        const [amountSearch, setAmountSearch] = useState("");
        const [selectedServiceProvider, setSelectedServiceProvider] = useState("");
        const [selectedDepartment, setSelectedDepartment] = useState("");
        const [selectedCategory, setSelectedCategory] = useState("");
        // Add new amount filter states
        const [amountFilterType, setAmountFilterType] = useState("exact");
        const [amountStart, setAmountStart] = useState("");
        const [amountEnd, setAmountEnd] = useState("");

        // Fetch service providers
        const GetServiceProviders = async () => {
            try {
                const res = await apiRequest("getServiceProviders");
                if (res.data?.isSuccess) {
                    setServiceProvidersList(res.data?.serviceProviders);
                }
            } catch (error) {
                console.error("Error fetching service providers:", error);
            }
        };

        useEffect(() => {
            GetServiceProviders();
        }, []);

        // Extract unique tax types from data
        const taxTypes = useMemo(() => {
            const types = new Set(
                taxPayments.map((payment) => payment.tax_type)
            );
            return Array.from(types).filter((type) => type); // Filter out any null/undefined values
        }, [taxPayments]);

        // Extract unique service providers from data
        const serviceProviders = useMemo(() => {
            const providers = new Set(
                taxPayments.map((payment) => payment.service_provider)
            );
            return Array.from(providers).filter((provider) => provider);
        }, [taxPayments]);

        // Extract unique departments from data
        const departments = useMemo(() => {
            const depts = new Set(
                taxPayments
                    .filter((payment) => payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts))
                    .flatMap((payment) =>
                        payment.breakdownAmounts
                            .filter((breakdown) => breakdown?.department)
                            .map((breakdown) => breakdown.department)
                    )
            );
            return Array.from(depts).filter((dept) => dept);
        }, [taxPayments]);

        // Extract unique categories from data
        const categories = useMemo(() => {
            const cats = new Set(
                taxPayments
                    .filter((payment) => payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts))
                    .flatMap((payment) =>
                        payment.breakdownAmounts
                            .filter((breakdown) => breakdown?.category)
                            .map((breakdown) => breakdown.category)
                    )
            );
            return Array.from(cats).filter((cat) => cat);
        }, [taxPayments]);

        const handleFilters = () => {
            let filtered = [...taxPayments];

            // Filter by expense date range
            if (expenseStartDate) {
                filtered = filtered.filter(
                    (payment) => new Date(payment.createdAt) >= new Date(expenseStartDate)
                );
            }
            if (expenseEndDate) {
                filtered = filtered.filter(
                    (payment) => new Date(payment.createdAt) <= new Date(expenseEndDate)
                );
            }

            // Filter by payment date range
            if (paymentStartDate) {
                filtered = filtered.filter(
                    (payment) => new Date(payment.payment_date) >= new Date(paymentStartDate)
                );
            }
            if (paymentEndDate) {
                filtered = filtered.filter(
                    (payment) => new Date(payment.payment_date) <= new Date(paymentEndDate)
                );
            }

            // Filter by tax type
            if (taxType) {
                filtered = filtered.filter(
                    (payment) => payment.tax_type === taxType
                );
            }

            // Filter by vendor name
            if (vendorName) {
                filtered = filtered.filter((payment) =>
                    payment?.vendor_name?.toLowerCase().includes(vendorName.toLowerCase())
                );
            }

            // Filter by amount based on filter type
            if (amountStart) {
                if (amountFilterType === "exact") {
                    filtered = filtered.filter(
                        (payment) => payment.totalAmount === Number(amountStart)
                    );
                } else if (amountFilterType === "less") {
                    filtered = filtered.filter(
                        (payment) => payment.totalAmount < Number(amountStart)
                    );
                } else if (amountFilterType === "greater") {
                    filtered = filtered.filter(
                        (payment) => payment.totalAmount > Number(amountStart)
                    );
                } else if (amountFilterType === "range" && amountEnd) {
                    filtered = filtered.filter(
                        (payment) => 
                            payment.totalAmount >= Number(amountStart) && 
                            payment.totalAmount <= Number(amountEnd)
                    );
                }
            }

            // Filter by service provider
            if (selectedServiceProvider) {
                filtered = filtered.filter(
                    (payment) => payment.service_provider === selectedServiceProvider
                );
            }

            // Filter by department
            if (selectedDepartment) {
                filtered = filtered.filter((payment) =>
                    payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts)
                        ? payment.breakdownAmounts.some(
                            (breakdown) => breakdown?.department === selectedDepartment
                        )
                        : false
                );
            }

            // Filter by category
            if (selectedCategory) {
                filtered = filtered.filter((payment) =>
                    payment?.breakdownAmounts && Array.isArray(payment.breakdownAmounts)
                        ? payment.breakdownAmounts.some(
                            (breakdown) => breakdown?.category === selectedCategory
                        )
                        : false
                );
            }

            setFilteredData(filtered);
            setCurrentPage(1);
        };

        const resetFilters = () => {
            setExpenseStartDate("");
            setExpenseEndDate("");
            setPaymentStartDate("");
            setPaymentEndDate("");
            setTaxType("");
            setVendorName("");
            setAmountSearch("");
            setSelectedServiceProvider("");
            setSelectedDepartment("");
            setSelectedCategory("");
            setAmountFilterType("exact");
            setAmountStart("");
            setAmountEnd("");
            setFilteredData(taxPayments);
            setCurrentPage(1);
        };

        const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(e.target.value);
            setFilteredData(taxPayments.filter((taxPayment) =>
                taxPayment?.tax_type.toLowerCase().includes(e.target.value.toLowerCase()) ||
                GetDate(taxPayment?.createdAt).includes(e.target.value)
            ));
            setCurrentPage(1);
        }

        const getPageData = () => {
            const startIndex = (currentPage - 1) * 20;
            const endIndex = startIndex + 20;
            return filteredData.slice(startIndex, endIndex);
        }

        const handlePageChange = (page: number) => {
            setCurrentPage(page);
        }

        // Helper function to get service provider name from ID
        const getServiceProviderName = (id: string) => {
            const provider = serviceProvidersList.find(provider => provider._id === id);
            return provider ? provider.name : id;
        }

        const DownloadCSV = () => {
            //prepare headers
            const headers = ["Expense Date", "Payment Date", "Tax Type", "Amount Paid", "Service Provider", "Description", "Screenshot", "Added By", "Paid By Account"];
            //prepare rows
            const rows = getPageData().map((taxPayment) => [
                GetDate(taxPayment?.createdAt),
                GetDate(taxPayment?.payment_date),
                taxPayment?.tax_type,
                taxPayment?.totalAmount,
                getServiceProviderName(taxPayment?.service_provider),
                taxPayment?.description,
                taxPayment?.screenshot,
                taxPayment?.addedBy,
                taxPayment?.paidByOurAccounts?.[0]
            ]);
            const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'tax-payments.csv';
            a.click();
        };

        return (
            <>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {/* Expense Date Filters */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Expense Date Range</label>
                        <div className="flex gap-2">
                            <input
                                type="date"
                                value={expenseStartDate}
                                onChange={(e) => setExpenseStartDate(e.target.value)}
                                className="border p-2 rounded w-full bg-transparent"
                            />
                            <input
                                type="date"
                                value={expenseEndDate}
                                onChange={(e) => setExpenseEndDate(e.target.value)}
                                className="border p-2 rounded w-full bg-transparent"
                            />
                        </div>
                    </div>

                    {/* Payment Date Filters */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">
                            Payment Date Range
                        </label>
                        <div className="flex gap-2">
                            <input
                                type="date"
                                value={paymentStartDate}
                                onChange={(e) => setPaymentStartDate(e.target.value)}
                                className="border p-2 rounded w-full bg-transparent"
                            />
                            <input
                                type="date"
                                value={paymentEndDate}
                                onChange={(e) => setPaymentEndDate(e.target.value)}
                                className="border p-2 rounded w-full bg-transparent"
                            />
                        </div>
                    </div>

                    {/* Tax Type Search */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Tax Type</label>
                        <select
                            value={taxType}
                            onChange={(e) => setTaxType(e.target.value)}
                            className="border p-2 rounded w-full bg-transparent"
                        >
                            <option value="">All Tax Types</option>
                            {taxTypes.map((type) => (
                                <option key={type} value={type}>
                                    {type}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Vendor Name Search */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Vendor Name</label>
                        <input
                            type="text"
                            placeholder="Search vendor name..."
                            value={vendorName}
                            onChange={(e) => setVendorName(e.target.value)}
                            className="border p-2 rounded w-full bg-transparent"
                        />
                    </div>

                    {/* Amount Search */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Amount</label>
                        <div className="flex gap-2">
                            <select
                                value={amountFilterType}
                                onChange={(e) => {
                                    setAmountFilterType(e.target.value);
                                    setAmountStart("");
                                    setAmountEnd("");
                                }}
                                className="border p-2 rounded bg-transparent w-1/3"
                            >
                                <option value="exact">Exact</option>
                                <option value="less">Less than</option>
                                <option value="greater">Greater than</option>
                                <option value="range">Range</option>
                            </select>
                            <div className="flex gap-2 w-2/3">
                                {amountFilterType === "range" ? (
                                    <>
                                        <input
                                            type="number"
                                            placeholder="From"
                                            value={amountStart}
                                            onChange={(e) => setAmountStart(e.target.value)}
                                            className="border p-2 rounded w-1/2 bg-transparent"
                                        />
                                        <input
                                            type="number"
                                            placeholder="To"
                                            value={amountEnd}
                                            onChange={(e) => setAmountEnd(e.target.value)}
                                            className="border p-2 rounded w-1/2 bg-transparent"
                                        />
                                    </>
                                ) : (
                                    <input
                                        type="number"
                                        placeholder={
                                            amountFilterType === "exact"
                                                ? "Enter amount"
                                                : amountFilterType === "less"
                                                    ? "Less than"
                                                    : "Greater than"
                                        }
                                        value={amountStart}
                                        onChange={(e) => setAmountStart(e.target.value)}
                                        className="border p-2 rounded w-full bg-transparent"
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Service Provider Dropdown */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Service Provider</label>
                        <select
                            value={selectedServiceProvider}
                            onChange={(e) => setSelectedServiceProvider(e.target.value)}
                            className="border p-2 rounded w-full bg-transparent"
                        >
                            <option value="">All Service Providers</option>
                            {serviceProvidersList.map((provider) => (
                                <option key={provider._id} value={provider._id}>
                                    {provider.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Department Dropdown */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Department</label>
                        <select
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                            className="border p-2 rounded w-full bg-transparent"
                        >
                            <option value="">All Departments</option>
                            {departments.map((department) => (
                                <option key={department} value={department}>
                                    {department}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Category Dropdown */}
                    <div className="flex flex-col gap-2">
                        <label className="text-sm font-medium">Category</label>
                        <select
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            className="border p-2 rounded w-full bg-transparent"
                        >
                            <option value="">All Categories</option>
                            {categories.map((category) => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex justify-end gap-2 mb-4">
                    <button
                        className="bg-primary text-white p-2 rounded px-4 shadow-md font-bold"
                        onClick={handleFilters}
                    >
                        Apply Filters
                    </button>
                    <button
                        className="bg-gray-500 text-white p-2 rounded px-4 shadow-md font-bold"
                        onClick={resetFilters}
                    >
                        Reset Filters
                    </button>
                    <button
                        className="bg-primary text-white p-2 rounded px-4 shadow-md font-bold"
                        onClick={DownloadCSV}
                    >
                        Download CSV
                    </button>
                </div>

                <div className='flex flex-row mb-4'>
                    <input type="text" placeholder='Search' value={searchText} onChange={handleSearch} className='border p-2 rounded w-full bg-transparent' />
                    <button className='bg-primary text-white p-2 rounded'
                        onClick={() => {
                            setSearchText('');
                            setFilteredData(taxPayments);
                            setCurrentPage(1);
                        }}
                    >Reset</button>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full mb-20 text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="px-4 py-4">
                                    Expense Date
                                </th>
                                <th scope="col" className="px-4 py-4">
                                    Date of Tax Payment
                                </th>
                                <th scope="col" className="px-4 py-4">
                                    Tax Type
                                </th>
                                <th scope="col" className="px-4 py-4">
                                    Amount Paid
                                </th>
                                <th scope="col" className="px-4 py-4 text-left">
                                    Service Provider
                                </th>
                                <th scope="col" className="px-4 py-4 text-left">
                                    Description
                                </th>
                                <th scope="col" className="px-4 py-4 text-left">
                                    Screenshot
                                </th>
                                <th scope="col" className="px-4 py-3 text-center">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {getPageData().map((taxPayment) => (
                                <tr className="border-b" key={taxPayment?._id}>
                                    <td className="px-4 py-3">{GetDate(taxPayment?.createdAt)}</td>
                                    <td className="px-4 py-3">{GetDate(taxPayment?.payment_date)}</td>
                                    <td className="px-4 py-3">{taxPayment?.tax_type === "" ? "N/A" : taxPayment?.tax_type}</td>
                                    <td className="px-4 py-3">{taxPayment?.totalAmount === 0 ? "N/A" : taxPayment?.totalAmount}</td>
                                    <td className="px-4 py-3">{taxPayment?.service_provider ? getServiceProviderName(taxPayment?.service_provider) : "N/A"}</td>
                                    <td className="px-4 py-3">{taxPayment?.description === "" ? "N/A" : taxPayment?.description}</td>
                                    <td className="px-4 py-3">
                                        <div className='text-blue-500 cursor-pointer underline'
                                            onClick={() => {
                                                window.open(taxPayment?.screenshot, '_blank');
                                            }}
                                        >
                                            Screenshot Link
                                        </div>
                                    </td>
                                    <td className="px-4 py-3 text-center flex items-center justify-center">
                                        <div className="cursor-pointer flex items-center gap-4">
                                            {showEdit && <div className='cursor-pointer'
                                                onClick={() => {
                                                    setOpenAddTaxPaymentModal(true);
                                                    setModalType("edit");
                                                    setSelectedTaxPayment(taxPayment);
                                                }}
                                            >
                                                <EditIcon
                                                    size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                                />
                                            </div>}
                                            <div className='cursor-pointer'
                                                onClick={() => {
                                                    setOpenViewTaxPaymentModal(true);
                                                    setSelectedTaxPayment(taxPayment);
                                                }}
                                            >

                                                <EyeIcon
                                                    size='w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300'
                                                />
                                            </div>
                                            <div className='cursor-pointer'
                                                onClick={() => {
                                                    DeleteTaxPayment(taxPayment?._id);
                                                }}
                                            >
                                                <DeleteIcon
                                                    size='w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300'
                                                />
                                            </div>
                                        </div>


                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='flex flex-row gap-2 justify-center items-center'>
                        <button
                            disabled={currentPage === 1}
                            className='bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed px-4 shadow-md font-bold'
                            onClick={() => handlePageChange(currentPage - 1)}
                        >Previous</button>
                        <div className='flex flex-row gap-2 items-center justify-center p-2 rounded-md bg-primary text-white px-4 min-w-10 shadow-md font-bold'>
                            {currentPage} / {Math.ceil(filteredData.length / 20)}
                        </div>
                        <button
                            disabled={currentPage === Math.ceil(filteredData.length / 20)}
                            className='bg-primary text-white p-2 rounded disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed px-4 shadow-md font-bold'
                            onClick={() => handlePageChange(currentPage + 1)}
                        >Next</button>
                    </div>

                </div>
            </>
        )
    }