import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { UserContext } from '../state-management/user-context'

import { logout } from '(apis)/(shared-apis)/auth-apis';
import { ErrorIcon } from 'shared-components/ui/Icons';
import { Logos } from 'constants/theme-config';
import { AcademicRoutes, ExpertSolution, FinanceRoutes, Marketing, Plans, VaultRoutes } from "constants/constantvalues";
import { LockFilled } from '@ant-design/icons';

interface LayoutProps {
  children: React.ReactNode;
  permissions?: string[];
  redirectUrl: string;
}


export default function Layout({ children, permissions, redirectUrl }: LayoutProps) {

  const context = useContext(UserContext)
  const [open, setOpen] = React.useState(true);
  const [permission, setPermission] = React.useState(true);
  if (!context) {
    throw new Error('Layout must be used within a UserProvider');
  }
  const { user, isResolved } = context;
  const location = useLocation();
  const currentPath = location.pathname.toLocaleLowerCase();
  const navigate = useNavigate();

  useEffect(() => {
    if (isResolved) {
      if (!user.isloggedin) {
        const encodedCurrentPath = encodeURIComponent(location.pathname + location.search);
        navigate(`/login?redirect_url=${encodedCurrentPath}`);
      }
    }
  }, [user.isloggedin, isResolved, location.pathname, location.search]);

  React.useEffect(() => {
    if (isResolved) {
      if (permissions && Array.isArray(permissions) && permissions.length > 0) {
        if (user && user.admin && Array.isArray(user.admin.pagepermissions)) {
          // Remove "All" permission from the list
          const otherPermissions = permissions.filter(perm => perm !== "All");
          
          if (HasPerm(otherPermissions)) {
            setPermission(true);
          } else {
            setPermission(false);
          }
        } else {
          console.error("User permissions are not properly defined");
          setPermission(false);
        }
      } else {
        // If no permissions are required, allow access by default
        setPermission(true);
      }
    }
  }, [isResolved, permissions, user]);

  const HasPerm = (perm: string[]): boolean => {
    // Check if user and user.admin are defined
    if (!user || !user.admin) {
      return false;
    }

    // Check if pagepermissions is defined and is an array
    if (!user.admin.pagepermissions || !Array.isArray(user.admin.pagepermissions)) {
      return false;
    }

    return perm.some(p =>
      user.admin.pagepermissions.some(pp => pp.toLowerCase() === p.toLowerCase())
    );
  }

  const Logout = () => {
    logout().then((res) => {
      window.location.href = "/login";
    })
  }

  const [sidebar, setSidebar] = React.useState(true);

  useEffect(() => {
    if (currentPath.includes("expert-interface")) {
      setSidebar(false);
    }
  }, [currentPath]);

  let sections = []

  if (process.env.REACT_APP_CATEGORY === 'FINANCE'){
    sections = [
      { title: 'Finance', routes: FinanceRoutes },
    ];
  }
  else {
    sections = [
      { title: 'Academic', routes: AcademicRoutes },
      { title: 'Vault', routes: VaultRoutes },
      { title: 'Expert Solution', routes: ExpertSolution },
      { title: 'Plans', routes: Plans },
      { title: 'Marketing', routes: Marketing },
    ];
  }

  const CheckIfSectionAllPermissionsNotAlowed = (routes: any[]) => {
    if (routes.length === 0) {
      return false;
    }
    return routes.every(route => !HasPerm(route.permissions));    
  }

  const DontDisplayHeader = () => {
    if (currentPath.includes("expert-interface")) {
      return true;
    }
    return false;
  }

  return (
    <div className={`overflow-x-hidden ${DontDisplayHeader() ? "" : "mx-4"}`}>
      {!DontDisplayHeader() && (
      <div className='flex flex-row justify-between  items-center text-gray-700 p-3 border-b border-gray-300 '>
        <div className='flex flex-row items-center justify-start gap-4'>
          <div className='p-1 cursor-pointer rounded-full hover:bg-gray-300 transition duration-200' onClick={() => setOpen(!open)}>
            <MenuIcon className='text-4xl' onClick={() => setSidebar(!sidebar)} />
          </div>
          <img
            className="w-22 h-10"
            src={Logos[process.env.REACT_APP_CATEGORY]}
            alt="logo"
          />
        </div>
        <div className='flex flex-row items-center justify-end gap-1'>
          <div className='font-semibold text-lg mr-2 w-10 h-10 bg-gray-50 border border-gray-300 rounded-full flex items-center justify-center text-primary'>
            {user.fullname.charAt(0)}
          </div>
          <div className='font-semibold text-lg'>{user.fullname}</div>
          <div className='p-1 cursor-pointer rounded-full hover:bg-gray-300 transition duration-200'
            onClick={() => {
              Logout();
              // window.location.href = "/login";
            }}
          >
            <ExitToAppIcon className='text-4xl cursor-pointer' />
          </div>
        </div>
      </div>
      )}

      <div className='w-full flex min-h-[calc(120vh)]'>
        {sidebar && (
        <div className='shadow-md rounded-lg overflow-hidden w-[20%] hidden md:block'>
          <div className='h-full bg-gray-50 p-4 overflow-y-auto'>
            {sections.map((section, index) => (
              <>
              {!CheckIfSectionAllPermissionsNotAlowed(section.routes) && (
              <Section key={index} title={section.title} routes={section.routes} />
              )}
              </>
            ))}
          </div>
        </div>
       )}
        {!children ?
          <div className='w-full md:w-[90%] mx-auto flex items-center justify-center'>
            <div className='text-center flex flex-col gap-4 bg-red-100 p-8 rounded-lg'>
              <div className='flex items-center justify-center gap-2'>
                <ErrorIcon size="w-8 h-8" color="text-gray-700" />
              </div>
              <h1>404</h1>
              <div>
                Page Not Found
              </div>
            </div>
          </div> :
          <div className='w-full'>
            {permission ? (
              <div className={`${DontDisplayHeader() ? "" : "mt-10 md:mx-5 w-[95%]"}`}>{children}</div>
            ) : (
              <div className='h-96 flex items-center justify-center'>
                <div className='text-center flex flex-col gap-4 bg-red-100 p-8 rounded-lg'>
                  <div className='flex items-center justify-center gap-2'>
                    <ErrorIcon size="w-8 h-8" color="text-gray-700" />
                  </div>
                  <h1>Not Authorized</h1>
                  <div>
                    You do not have permissions to view this page.
                    <br /> Please contact your supervisor if you believe this is an error.
                  </div>
                </div>
              </div>
            )}
          </div>}

      </div>
    </div>
  )
}


const Section = ({ title, routes }) => (
  <div className="bg-[#F7F6F7] flex flex-col justify-start shadow-xl rounded-xl p-4 min-h-32">
            <div className='text-primary text-center font-bold p-1 rounded-full mb-2 shadow bg-primary text-white'>      {title}
    </div>
    <div className="flex flex-wrap justify-start items-center gap-2 mt-4">
      <RouteButtons routes={routes} />
    </div>
  </div>
);

const RouteButtons = ({ routes }) => {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Layout must be used within a UserProvider");
  }
  const { user } = context;
  const HasPerm = (perm: string[]): boolean => {
    if (!user || !user.admin) {
      return false;
    }

    if (!Array.isArray(user?.admin?.pagepermissions)) {
      return false;
    }

    return perm.some(p =>
      user.admin.pagepermissions.some(pp => pp.toLowerCase() === p.toLowerCase())
    );
  }

  return (
    <div className="flex flex-wrap justify-start items-center gap-2">
      {routes.map((route, index) => (
        <>
        {HasPerm(route.permissions) ? 
        <button
          key={index} className="flex w-full gap-2 p-1 text-gray-700 text-sm cursor-pointer hover:bg-gray-50 shadow mb-1 rounded px-4 font-semibold hover:text-gray-500 transition duration-200"
          onClick={() => navigate(route.path)}
          disabled={!HasPerm(route.permissions)}
        >
          {HasPerm(route.permissions) ? "" : <LockFilled />}
          {route.name}
        </button>
        : null}
        </>
      ))}
    </div>
  );
}
