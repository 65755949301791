"use client"

import { useState, useRef, useEffect, useContext } from "react"
import * as d3 from "d3"
import { apiRequest } from "(apis)/api-interface/api-handler"
import { useParams } from "react-router-dom"
import { useTimer } from "react-timer-hook"
import { ExpertAPI } from "(apis)/(shared-apis)/expert-api"
import toast, { Toaster } from "react-hot-toast"
import { UserContext } from "state-management/user-context"
import RecordRTC from "recordrtc"
import {
  Paintbrush,
  Play,
  Square,
  Trash2,
  Eye,
  EyeOff,
  Upload,
  Send,
  ChevronLeft,
  ChevronRight,
  Eraser,
  Clock,
  Video,
  CheckCircle,
} from "lucide-react"

// Utility function for class names
const cn = (...classes) => classes.filter(Boolean).join(" ")

export default function ExpertInterface() {
  // State management
  const [penColor, setPenColor] = useState("#3B82F6") // Blue default
  const [penWidth, setPenWidth] = useState(2)
  const [isRecording, setIsRecording] = useState(false)
  const [isDrawing, setIsDrawing] = useState(false)
  const [isErasing, setIsErasing] = useState(false)
  const [screenStream, setScreenStream] = useState(null)
  const [DoubtGet, setDoubtGet] = useState(null)
  const [showDoubt, setShowDoubt] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [userDoubt, setUserDoubt] = useState(null)
  const [recorder, setRecorder] = useState(null)
  const [recordedBlob, setRecordedBlob] = useState(null)
  const [webcamStream, setWebcamStream] = useState(null)
  const [uploadedFileName, setUploadedFileName] = useState(null)
  const [uploadedBlob, setUploadedBlob] = useState(null)
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [activeTab, setActiveTab] = useState("draw") // draw, record, upload

  // Refs
  const svgRef = useRef(null)
  const videoRef = useRef(null)

  // Router params
  const { type, id } = useParams()

  // Context
  const { user } = useContext(UserContext)

  // Timer setup
  const timerDuration = 180
  const { totalSeconds, seconds, minutes, pause, restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + timerDuration * 1000),
    autoStart: false,
    onExpire: () => setShowModal(true),
  })

  // Fetch doubt data on component mount
  useEffect(() => {
    const fetchDoubtData = async () => {
      try {
        if (id && type === "subjective") {
          const response = await apiRequest("GetSubjectiveDoubt", {}, [id])
          const data = response.data
          setDoubtGet(data?.Details)
        } else if (id && type === "objective") {
          const response = await ExpertAPI("get-user-doubt", id)
          if (response.data.success) {
            const data = response.data.data
            setUserDoubt(data)
          }
        }
      } catch (error) {
        toast.error("Failed to fetch doubt details")
        console.error("Error fetching doubt:", error)
      }
    }

    fetchDoubtData()
  }, [id, type])

  // Drawing canvas setup
  useEffect(() => {
    if (svgRef.current) {
      const svg = d3.select(svgRef.current)
      const width = svg.node()?.clientWidth || 800
      const height = svg.node()?.clientHeight || 600

      svg.attr("width", width).attr("height", height)

      const getPointerPosition = (event) => {
        const touch = event.touches ? event.touches[0] : event
        const svgElement = svgRef.current
        const rect = svgElement.getBoundingClientRect()

        const x = touch.clientX - rect.left
        const y = touch.clientY - rect.top
        return [x, y]
      }

      const handleStart = (event) => {
        event.preventDefault()
        setIsDrawing(true)
        const [x, y] = getPointerPosition(event)
        svg
          .append("path")
          .attr("stroke", isErasing ? "#FFFFFF" : penColor)
          .attr("stroke-width", isErasing ? penWidth * 2 : penWidth)
          .attr("fill", "none")
          .attr("d", `M${x},${y}`)
      }

      const handleMove = (event) => {
        event.preventDefault()
        if (!isDrawing) return
        const [x, y] = getPointerPosition(event)
        const activePath = svg.select("path:last-child")
        const d = activePath.attr("d")
        activePath.attr("d", `${d} L${x},${y}`)
      }

      const handleEnd = () => {
        setIsDrawing(false)
      }

      svg.node().addEventListener("touchstart", handleStart, { passive: false })
      svg.node().addEventListener("touchmove", handleMove, { passive: false })
      svg.node().addEventListener("touchend", handleEnd)

      svg.on("mousedown", handleStart)
      svg.on("mousemove", handleMove)
      svg.on("mouseup", handleEnd)
      svg.on("mouseleave", handleEnd)

      return () => {
        svg.node().removeEventListener("touchstart", handleStart)
        svg.node().removeEventListener("touchmove", handleMove)
        svg.node().removeEventListener("touchend", handleEnd)
        svg.on("mousedown", null)
        svg.on("mousemove", null)
        svg.on("mouseup", null)
        svg.on("mouseleave", null)
      }
    }
  }, [penColor, penWidth, isDrawing, isErasing])

  // Timer effects
  useEffect(() => {
    if (isRecording) {
      const time = new Date()
      time.setSeconds(time.getSeconds() + timerDuration)
      restart(time)
    }
  }, [isRecording])

  useEffect(() => {
    if (totalSeconds <= 0) {
      stopRecording()
      setShowModal(true)
    }
  }, [totalSeconds])

  // Recording functions
  const startRecording = async () => {
    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true,
      })
      const webcam = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      })

      const mergedStream = new MediaStream()
      screenStream.getTracks().forEach((track) => mergedStream.addTrack(track))
      webcam.getTracks().forEach((track) => mergedStream.addTrack(track))

      const mediaRecorder = new RecordRTC(mergedStream, {
        type: "video",
        mimeType: "video/mp4",
      })

      mediaRecorder.startRecording()
      setRecorder(mediaRecorder)
      setIsRecording(true)
      setScreenStream(screenStream)

      // Start timer
      const time = new Date()
      time.setSeconds(time.getSeconds() + timerDuration)
      restart(time)

      toast.success("Recording started")
    } catch (error) {
      console.error("Error starting recording:", error)
      toast.error("Failed to start recording")
    }
  }

  const stopRecording = () => {
    if (recorder && recorder.state !== "stopped") {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob()
        setRecordedBlob(blob)
        setIsRecording(false)
        pause()

        if (screenStream) {
          screenStream.getTracks().forEach((track) => track.stop())
          setScreenStream(null)
        }

        toast.success("Recording stopped")
      })
    }
  }

  // Canvas actions
  const clearCanvas = () => {
    if (svgRef.current) {
      d3.select(svgRef.current).selectAll("path").remove()
      toast.success("Canvas cleared")
    }
  }

  // File upload handler
  const handleVideoUpload = (event) => {
    const file = event.target.files[0]
    if (!file) return

    const reader = new FileReader()

    reader.onloadend = () => {
      const blob = new Blob([reader.result], { type: file.type })
      setUploadedBlob(blob)
      setUploadedFileName(file.name)
      toast.success(`File "${file.name}" uploaded`)
    }

    reader.readAsArrayBuffer(file)
  }

  // Modal handlers
  const handleReRecord = () => {
    setShowModal(false)
    startRecording()
  }

  const handleSubmit = () => {
    setShowModal(false)
    uploadVideo()
  }

  // Video upload to server
  const uploadVideo = async () => {
    try {
      if (DoubtGet?.ExpireDate && type === "objective") {
        const date = new Date(DoubtGet?.ExpireDate)
        const currentDate = new Date()
        if (date < currentDate) {
          toast.error("Doubt has expired")
          stopRecording()
          return
        }
      }

      let videoToUpload = uploadedBlob

      if (!uploadedBlob && recordedBlob) {
        videoToUpload = recordedBlob
      }

      if (!videoToUpload) {
        toast.error("Please record or upload a video first")
        return
      }

      toast.loading("Uploading video...")

      if (type === "objective") {
        const formData = new FormData()
        formData.append("video", videoToUpload)
        formData.append("username", user.username)
        formData.append("DoubtId", userDoubt?._id)
        const res = await apiRequest("uploadExpertVideo", formData)

        toast.dismiss()
        if (res.data.success) {
          toast.success("Your video is uploaded successfully and will be reviewed by our students")
        } else {
          toast.error("There was an error in uploading your video")
        }
      } else if (type === "subjective") {
        const formData = new FormData()
        formData.append("video", videoToUpload)
        formData.append("username", user.username)
        formData.append("DoubtId", DoubtGet?._id)
        formData.append("topic", DoubtGet?.meta?.topic)
        formData.append("subtopic", DoubtGet?.meta?.subtopic)
        formData.append("resource", "Premed QBank")

        const res = await apiRequest("uploadSubjective", formData)

        toast.dismiss()
        if (res.data.success) {
          toast.success("Your video is uploaded successfully and will be reviewed by our students")
        } else {
          toast.error(res?.data?.message)
        }
      }
    } catch (error) {
      toast.dismiss()
      toast.error(error.response?.data?.message || error.message)
    }
  }

  // Toggle eraser mode
  const toggleEraser = () => {
    setIsErasing(!isErasing)
    toast.success(isErasing ? "Pen mode activated" : "Eraser mode activated")
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Main content area */}
      <div className="flex-grow relative pt-16 md:pt-0">
        {/* Canvas */}
        <svg
          ref={svgRef}
          className="w-full h-[calc(100vh-4rem)] md:h-[300vh]"
          style={{
            backgroundImage: `url("https://premedpk-cdn.sgp1.digitaloceanspaces.com/ManulaUpload/7cbb3822-ce01-4064-b90d-94af3ab7c9c1.png")`,
            backgroundSize: "contain",
            backgroundRepeat: "repeat",
            backgroundPosition: "start",
            objectFit: "contain",
          }}
        ></svg>

        {/* Sidebar */}
        <div
          className={cn(
            "fixed left-0 top-1/2 -translate-y-1/2 z-50 transition-all duration-300 ease-in-out",
            isCollapsed ? "translate-x-[-90%]" : "translate-x-0",
          )}
        >
          {/* Toggle button */}
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="absolute top-1/2 -translate-y-1/2 right-0 translate-x-1/2 bg-blue-600 text-white rounded-full p-2 shadow-lg hover:bg-blue-700 transition-all"
            aria-label={isCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isCollapsed ? <ChevronRight size={16} /> : <ChevronLeft size={16} />}
          </button>

          {/* Main sidebar container */}
          <div className="bg-white rounded-r-2xl shadow-xl p-4 w-64 flex flex-col gap-4 border border-gray-200">
            {/* Header */}
            <div className="flex items-center justify-between border-b border-gray-200 pb-3">
              <h2 className="text-lg font-bold flex items-center gap-2 text-gray-800">
                <Paintbrush className="text-blue-600" size={20} />
                <span>Expert Tools</span>
              </h2>
            </div>

            {/* Tabs */}
            <div className="flex border-b border-gray-200">
              <button
                onClick={() => setActiveTab("draw")}
                className={cn(
                  "flex-1 py-2 text-sm font-medium",
                  activeTab === "draw"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-500 hover:text-gray-700",
                )}
              >
                Draw
              </button>
              <button
                onClick={() => setActiveTab("record")}
                className={cn(
                  "flex-1 py-2 text-sm font-medium",
                  activeTab === "record"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-500 hover:text-gray-700",
                )}
              >
                Record
              </button>
              <button
                onClick={() => setActiveTab("upload")}
                className={cn(
                  "flex-1 py-2 text-sm font-medium",
                  activeTab === "upload"
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : "text-gray-500 hover:text-gray-700",
                )}
              >
                Upload
              </button>
            </div>

            {/* Draw Tab Content */}
            {activeTab === "draw" && (
              <div className="space-y-4">
                {/* Pen Settings */}
                <div className="space-y-3">
                  <label className="block text-sm font-medium text-gray-700">Pen Settings</label>

                  <div className="flex items-center gap-3">
                    <span className="text-xs text-gray-500">Color:</span>
                    <input
                      type="color"
                      value={penColor}
                      onChange={(e) => setPenColor(e.target.value)}
                      className="w-8 h-8 border-2 border-gray-300 rounded-full cursor-pointer"
                    />
                  </div>

                  <div className="space-y-2">
                    <span className="text-xs text-gray-500">Size:</span>
                    <div className="flex items-center justify-between px-2">
                      {[1, 2, 3, 4, 5].map((size) => (
                        <button
                          key={size}
                          className={cn(
                            "cursor-pointer transition-all duration-200 hover:scale-110 p-1 rounded-full",
                            penWidth === size ? "bg-gray-100 ring-2 ring-blue-500" : "",
                          )}
                          onClick={() => setPenWidth(size)}
                        >
                          <div
                            style={{
                              backgroundColor: penColor,
                              width: `${size * 4 + 4}px`,
                              height: `${size * 4 + 4}px`,
                            }}
                            className="rounded-full"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Drawing Actions */}
                <div className="space-y-3">
                  <label className="block text-sm font-medium text-gray-700">Actions</label>

                  <div className="grid grid-cols-2 gap-2">
                    <button
                      onClick={toggleEraser}
                      className={cn(
                        "flex items-center justify-center gap-1 px-3 py-2 text-xs font-medium rounded-lg transition-colors",
                        isErasing
                          ? "bg-blue-100 text-blue-700 border border-blue-300"
                          : "bg-gray-100 text-gray-700 border border-gray-300 hover:bg-gray-200",
                      )}
                    >
                      <Eraser size={14} />
                      {isErasing ? "Pen" : "Eraser"}
                    </button>

                    <button
                      onClick={clearCanvas}
                      className="flex items-center justify-center gap-1 bg-red-100 text-red-700 border border-red-300 hover:bg-red-200 px-3 py-2 text-xs font-medium rounded-lg transition-colors"
                    >
                      <Trash2 size={14} />
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Record Tab Content */}
            {activeTab === "record" && (
              <div className="space-y-4">
                <div className="space-y-3">
                  <label className="block text-sm font-medium text-gray-700">Screen Recording</label>

                  <div className="flex flex-col gap-2">
                    {!isRecording ? (
                      <button
                        onClick={startRecording}
                        className="flex items-center justify-center gap-2 bg-green-600 hover:bg-green-700 text-white px-4 py-3 text-sm font-medium rounded-lg transition-colors"
                      >
                        <Play size={16} className="fill-white" />
                        Start Recording
                      </button>
                    ) : (
                      <button
                        onClick={stopRecording}
                        className="flex items-center justify-center gap-2 bg-red-600 hover:bg-red-700 text-white px-4 py-3 text-sm font-medium rounded-lg transition-colors animate-pulse"
                      >
                        <Square size={16} />
                        Stop Recording ({minutes}:{seconds < 10 ? `0${seconds}` : seconds})
                      </button>
                    )}
                  </div>

                  {recordedBlob && (
                    <div className="mt-3 p-3 bg-gray-50 rounded-lg border border-gray-200">
                      <div className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-1">
                        <Video size={14} />
                        Recording Complete
                      </div>
                      <button
                        onClick={uploadVideo}
                        className="flex w-full items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 text-sm font-medium rounded-lg transition-colors"
                      >
                        <Send size={14} />
                        Submit Recording
                      </button>
                    </div>
                  )}
                </div>

                <div className="text-xs text-gray-500 p-2 bg-yellow-50 border border-yellow-200 rounded-lg">
                  <p className="font-medium text-yellow-700 mb-1">Recording Tips:</p>
                  <ul className="list-disc pl-4 space-y-1">
                    <li>Make sure your screen and microphone are working</li>
                    <li>Recording will automatically stop after 3 minutes</li>
                    <li>You can stop recording anytime</li>
                  </ul>
                </div>
              </div>
            )}

            {/* Upload Tab Content */}
            {activeTab === "upload" && (
              <div className="space-y-4">
                <div className="space-y-3">
                  <label className="block text-sm font-medium text-gray-700">Video Upload</label>

                  <div className="flex flex-col gap-3">
                    <div className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center hover:bg-gray-50 transition-colors">
                      <input
                        type="file"
                        accept="video/mp4"
                        onChange={handleVideoUpload}
                        className="hidden"
                        id="video-upload"
                      />
                      <label htmlFor="video-upload" className="cursor-pointer flex flex-col items-center gap-2">
                        <Upload size={24} className="text-gray-400" />
                        <span className="text-sm font-medium text-gray-700">Click to upload video</span>
                        <span className="text-xs text-gray-500">MP4 format recommended</span>
                      </label>
                    </div>

                    {uploadedFileName && (
                      <div className="p-3 bg-gray-50 rounded-lg border border-gray-200">
                        <div className="text-sm font-medium text-gray-700 mb-2 flex items-center gap-2">
                          <Video size={14} className="text-blue-600" />
                          <span className="truncate">{uploadedFileName}</span>
                        </div>
                        <button
                          onClick={uploadVideo}
                          className="flex w-full items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 text-sm font-medium rounded-lg transition-colors"
                        >
                          <Send size={14} />
                          Submit Video
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Doubt Viewer Toggle */}
            <div className="mt-auto pt-3 border-t border-gray-200">
              <button
                onClick={() => setShowDoubt(!showDoubt)}
                className={cn(
                  "flex w-full items-center justify-center gap-2 px-3 py-2 text-sm font-medium rounded-lg transition-colors",
                  showDoubt
                    ? "bg-blue-100 text-blue-700 border border-blue-300"
                    : "bg-gray-100 text-gray-700 border border-gray-300 hover:bg-gray-200",
                )}
              >
                {showDoubt ? <EyeOff size={16} /> : <Eye size={16} />}
                {showDoubt ? "Hide Question" : "Show Question"}
              </button>
            </div>
          </div>
        </div>

        {/* Doubt Viewers */}
        <ObjectiveDoubtViewer showDoubt={showDoubt} userDoubt={userDoubt} type={type} />

        <SubjectiveDoubtViewer showDoubt={showDoubt} DoubtGet={DoubtGet} type={type} />

        {/* Timer */}
        <TimerView minutes={minutes} seconds={seconds} isRecording={isRecording} />

        {/* Toast notifications */}
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              background: "#363636",
              color: "#fff",
              borderRadius: "8px",
            },
            success: {
              duration: 3000,
              iconTheme: {
                primary: "#10B981",
                secondary: "white",
              },
            },
            error: {
              duration: 4000,
              iconTheme: {
                primary: "#EF4444",
                secondary: "white",
              },
            },
          }}
        />
      </div>

      {/* Time's Up Modal */}
      {showModal && (
        <TimesUpModal
          showModal={showModal}
          handleReRecord={handleReRecord}
          handleSubmit={handleSubmit}
          setShowModal={setShowModal}
        />
      )}
    </div>
  )
}

// Objective Doubt Viewer Component
const ObjectiveDoubtViewer = ({ showDoubt, userDoubt, type }) => {
  if (!showDoubt || type !== "objective" || !userDoubt) return null

  return (
    <div className="fixed bottom-0 left-0 right-0 md:absolute md:top-0 md:left-0 md:right-auto z-10">
      <div className="bg-white p-5 rounded-lg shadow-lg w-full md:w-[30vw] max-h-[50vh] md:max-h-[80vh] overflow-y-auto mx-2 md:mx-0 mb-2 md:mb-0 border border-gray-200">
        <div className="mb-4">
          <div className="flex flex-wrap items-center gap-2 mb-3">
            {userDoubt?.subject && (
              <div className="bg-blue-100 text-blue-800 px-3 py-1 text-xs font-semibold rounded-full">
                {userDoubt.subject}
              </div>
            )}
            {userDoubt?.resource && (
              <div className="bg-purple-100 text-purple-800 px-3 py-1 text-xs font-semibold rounded-full">
                {userDoubt.resource}
              </div>
            )}
          </div>

          <h3 className="text-lg font-bold text-gray-900 mb-2">Question</h3>

          <div className="prose prose-sm max-w-none">
            <div
              dangerouslySetInnerHTML={{ __html: userDoubt?.description }}
              className="text-gray-700 whitespace-normal break-words"
            />
          </div>

          {userDoubt?.img && (
            <div className="mt-4 border border-gray-200 rounded-lg overflow-hidden">
              <img
                src={userDoubt.img || "/placeholder.svg"}
                alt="Question image"
                className="w-full h-auto object-contain"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

// Subjective Doubt Viewer Component
const SubjectiveDoubtViewer = ({ showDoubt, DoubtGet, type }) => {
  if (!showDoubt || type !== "subjective" || !DoubtGet) return null

  return (
    <div className="fixed top-0 right-0 z-10">
      <div className="bg-white p-5 rounded-lg shadow-lg w-full md:w-[30vw] max-h-[50vh] md:max-h-[80vh] overflow-y-auto mx-2 md:mx-0 mb-2 md:mb-0 border border-gray-200">
        <div className="mb-4">
          <h3 className="text-lg font-bold text-gray-900 mb-2">Question</h3>

          <div className="prose prose-sm max-w-none">
            <div
              dangerouslySetInnerHTML={{ 
                __html: DoubtGet?.QuestionText?.replace(/<[^>]*>?/g, '')
                  .replace(/\\n/g, ' ')
                  .replace(/\n/g, ' ')
                  .replace(/\s+/g, ' ')
                  .trim()
              }}
              className="text-gray-700 whitespace-normal break-words"
            />
          </div>

          {DoubtGet?.QuestionImage && (
            <div className="mt-4 border border-gray-200 rounded-lg overflow-hidden">
              <img
                src={DoubtGet.QuestionImage || "/placeholder.svg"}
                alt="Question image"
                className="w-full h-auto object-contain max-w-[300px]"
              />
            </div>
          )}
        </div>

        {DoubtGet?.Options && DoubtGet.Options.length > 0 && (
          <div className="mb-4">
            <h4 className="text-md font-bold text-gray-900 mb-2">Options</h4>
            <div className="space-y-2">
              {DoubtGet.Options.map((option, index) => (
                <div
                  key={index}
                  className={cn(
                    "p-3 rounded-lg border",
                    option.IsCorrect ? "bg-green-50 border-green-200" : "bg-white border-gray-200",
                  )}
                >
                  <div className="flex items-start gap-2">
                    <div
                      className={cn(
                        "flex-shrink-0 w-6 h-6 rounded-full flex items-center justify-center text-xs font-bold",
                        option.IsCorrect ? "bg-green-500 text-white" : "bg-gray-200 text-gray-700",
                      )}
                    >
                      {option.IsCorrect ? <CheckCircle size={16} /> : option.OptionLetter}
                    </div>
                    <div>
                      <div 
                        dangerouslySetInnerHTML={{ 
                          __html: option.OptionText?.replace(/<[^>]*>?/g, '')
                            .replace(/\\n/g, ' ')
                            .replace(/\n/g, ' ')
                            .replace(/\s+/g, ' ')
                            .trim()
                        }} 
                        className="text-gray-700 text-sm" 
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

// Timer View Component
const TimerView = ({ minutes, seconds, isRecording }) => {
  if (!isRecording && minutes === 0 && seconds === 0) return null

  return (
    <div className="fixed top-0 right-0 md:absolute z-10">
      <div
        className={cn(
          "flex items-center gap-2 bg-white p-2 px-4 rounded-lg shadow-lg font-bold mr-2 mt-2 border",
          isRecording ? "border-red-300 text-red-600 animate-pulse" : "border-gray-200 text-gray-700",
        )}
      >
        <Clock size={16} />
        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </div>
    </div>
  )
}

// Times Up Modal Component
const TimesUpModal = ({ showModal, handleReRecord, handleSubmit, setShowModal }) => {
  if (!showModal) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-xl shadow-2xl max-w-md w-full mx-4">
        <div className="text-center mb-6">
          <Clock className="mx-auto h-12 w-12 text-red-500 mb-2" />
          <h3 className="text-xl font-bold text-gray-900">Time's Up!</h3>
          <p className="text-gray-600 mt-2">Your recording session has ended. What would you like to do next?</p>
        </div>

        <div className="flex flex-col sm:flex-row gap-3 justify-center">
          <button
            onClick={handleReRecord}
            className="flex-1 flex items-center justify-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-4 py-3 text-sm font-medium rounded-lg transition-colors"
          >
            <Play size={16} />
            Record Again
          </button>
          <button
            onClick={handleSubmit}
            className="flex-1 flex items-center justify-center gap-2 bg-green-600 hover:bg-green-700 text-white px-4 py-3 text-sm font-medium rounded-lg transition-colors"
          >
            <Send size={16} />
            Submit Recording
          </button>
        </div>

        <button onClick={() => setShowModal(false)} className="mt-4 w-full text-gray-500 hover:text-gray-700 text-sm">
          Cancel
        </button>
      </div>
    </div>
  )
}

