import { apiHandler, apiRequest } from "(apis)/api-interface/api-handler";
import React, { useContext, useEffect, useState, useMemo } from "react";
import { Select } from "antd";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { categoryValues } from "constants/constantvalues";
import { X } from "@mui/icons-material";
import { ButtonFill } from "shared-components/ui/CustomButtons";
import toast, { Toaster } from "react-hot-toast";
import { UserContext } from "state-management/user-context";
import { DeleteIcon, EditIcon, EyeIcon } from "shared-components/ui/Icons";

export const IncomingRevenueModal = ({
  ShowModel,
  setShowModel,
  isEditMode,
  selectedUnTrackedPayment,
  fetchUnTrackedPayments,
  setModalType,
}: {
  ShowModel: boolean;
  setShowModel: (value: boolean) => void;
  isEditMode: boolean;
  selectedUnTrackedPayment: any;
  fetchUnTrackedPayments: () => void;
  setModalType: (value: string) => void;
}) => {
  const [departments, setDepartments] = useState([]);
  const { user } = useContext(UserContext);

  const GetDepartments = async () => {
    const res2 = await apiRequest("getBankAccounts");
    if (res2.data?.isSuccess) {
      setDepartments(res2.data?.departments);
    }
  };

  useEffect(() => {
    GetDepartments();
  }, []);

  interface BreakDownAmounts {
    category: string;
    amount: number;
    department: string;
  }

  const [formData, setFormData] = useState<{
    revenue_name: string;
    revenue_type: string;
    employee_email: string;
    employee_name: string;
    breakdownAmounts: BreakDownAmounts[];
    addedBy: string;
    description: string;
    modifiedBy: string;
    screenshot: string;
    // payment_date: Date;
    createdAt: Date;
  }>({
    revenue_name: "",
    revenue_type: "",
    employee_email: "",
    employee_name: "",
    breakdownAmounts: [],
    description: "",
    addedBy: "",
    modifiedBy: "",
    screenshot: "",
    // payment_date: new Date(),
    createdAt: new Date(),
  });

  useEffect(() => {
    if (selectedUnTrackedPayment && isEditMode) {
      setFormData({
        revenue_name: selectedUnTrackedPayment?.revenue_name,
        revenue_type: selectedUnTrackedPayment?.revenue_type,
        employee_email: selectedUnTrackedPayment?.employee_email,
        employee_name: selectedUnTrackedPayment?.employee_name,
        breakdownAmounts: selectedUnTrackedPayment?.breakdownAmounts.map(
          (item) => ({
            category: item.category,
            amount: item.amount,
            department: item.department,
          })
        ),
        createdAt: new Date(selectedUnTrackedPayment?.createdAt),
        addedBy: selectedUnTrackedPayment?.addedBy,
        modifiedBy: selectedUnTrackedPayment?.modifiedBy,
        screenshot: selectedUnTrackedPayment?.screenshot,
        description: selectedUnTrackedPayment?.description,
        // payment_date: new Date(
        //   selectedUnTrackedPayment?.payment_date || new Date()
        // ),
      });
    }
  }, [selectedUnTrackedPayment, isEditMode]);

  const handleSubmit = async () => {
    try {
      //if no screenshot is uploaded then make it empty
      if (
        !formData.screenshot &&
        selectedUnTrackedPayment?.screenshot &&
        selectedUnTrackedPayment?.screenshot !== "" &&
        isEditMode
      ) {
        formData.screenshot = selectedUnTrackedPayment?.screenshot;
      }

      //validate form data
      if (formData.breakdownAmounts.length === 0) {
        toast.error("Please add at least one breakdown amount");
        return;
      }
      //make sure all breakdown amounts are numbers and not negative
      formData.breakdownAmounts.forEach((item) => {
        if (isNaN(Number(item.amount)) || Number(item.amount) < 0) {
          toast.error("Please enter valid numbers for breakdown amounts");
          return;
        }
      });
      //iterate through breakdown amounts and make sure every field is filled
      formData.breakdownAmounts.forEach((item) => {
        if (!item.category || !item.amount || !item.department) {
          toast.error("Please fill all fields for breakdown amounts");
          return;
        }
      });
      //make sure employeeId and bankAccount are not empty
      if (!formData.employee_email || !formData.revenue_type) {
        toast.error("Please select an employee and a payment type");
        return;
      }
      //make sure total amount is not negative
      if (
        formData.breakdownAmounts.reduce(
          (sum, item) => sum + Number(item.amount),
          0
        ) < 0
      ) {
        toast.error("Total amount cannot be negative");
        return;
      }
      //make sure total amount is not zero
      if (
        formData.breakdownAmounts.reduce(
          (sum, item) => sum + Number(item.amount),
          0
        ) === 0
      ) {
        toast.error("Total amount cannot be zero");
        return;
      }
      //make sure user is logged in
      if (!user?.username) {
        toast.error("Please login to continue");
        return;
      }
      //make sure createdAt is not in the future
      // if (formData.createdAt > new Date()) {
      //     toast.error("Created at cannot be in the future");
      //     return;
      // }

      //make sure screenshot is not empty
      if (!formData.screenshot) {
        toast.error("Please upload a screenshot");
        return;
      }

      //make sure payment_date is not in the future
    //   if (formData.payment_date > new Date()) {
    //     toast.error("Payment date cannot be in the future");
    //     return;
    //   }

      // Check if in edit mode and no new screenshot is uploaded
      if (isEditMode && !formData.screenshot) {
        formData.screenshot = selectedUnTrackedPayment?.screenshot; // Retain the existing screenshot
      }

      const payload = {
        ...formData,
        totalAmount: formData.breakdownAmounts.reduce(
          (sum, item) => sum + Number(item.amount),
          0
        ),
        addedBy: isEditMode
          ? selectedUnTrackedPayment?.addedBy
          : user?.username,
        modifiedBy: isEditMode
          ? selectedUnTrackedPayment?.modifiedBy
          : user?.username,
      };

      const res = await apiHandler({
        data: payload,
        name: isEditMode ? "updateUnTrackedRevenue" : "addUnTrackedRevenue",
        params: isEditMode ? [`${selectedUnTrackedPayment?._id}`] : [],
      });

      if (res.data?.isSuccess) {
        toast.success("Payment submitted successfully");
        fetchUnTrackedPayments();
        setShowModel(false);
        setModalType("add");
        // Reset form
        setFormData({
          revenue_name: "",
          revenue_type: "",
          employee_email: "",
          employee_name: "",
          breakdownAmounts: [],
          addedBy: "",
          description: "",
          modifiedBy: "",
          screenshot: "",
        //   payment_date: new Date(),
          createdAt: new Date(),
        });
        window.location.reload();
      }
    } catch (error) {
      console.error("Payment submission error:", error);
      toast.error("Payment submission error");
    }
  };

  const convertToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        ShowModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-4xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {isEditMode ? "Update" : "Add New"} Revenue
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setFormData({
                  revenue_name: "",
                  revenue_type: "",
                  employee_email: "",
                  employee_name: "",
                  breakdownAmounts: [],
                  addedBy: "",
                  modifiedBy: "",
                  createdAt: new Date(),
                  screenshot: "",
                  description: "",
                });
                setShowModel(false);
                setModalType("add");
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/*SELECT EMPLOYEE*/}
          <div className="flex flex-col gap-2">
            <div className="flex justify-between gap-2">
              <div className="flex flex-col gap-2 w-full">
                <label htmlFor="employeeId">Product Name</label>
                <input
                  type="text"
                  placeholder="Product Name"
                  className="border p-2 rounded w-full bg-transparent"
                  value={formData.revenue_name}
                  onChange={(e) =>
                    setFormData({ ...formData, revenue_name: e.target.value })
                  }
                />
              </div>
            </div>

            <label htmlFor="revenue_type">Revenue Type</label>
            <input
              type="text"
              placeholder="Revenue Type"
              className="border p-2 rounded w-full bg-transparent"
              value={formData.revenue_type}
              onChange={(e) =>
                setFormData({ ...formData, revenue_type: e.target.value })
              }
            />

            <label htmlFor="employee_email">Employee Email</label>
            <input
              type="text"
              placeholder="Employee Email"
              className="border p-2 rounded w-full bg-transparent"
              value={formData.employee_email}
              onChange={(e) =>
                setFormData({ ...formData, employee_email: e.target.value })
              }
            />

            {/* Breakdown Amounts Section */}
            <div className="mt-4">
              <label className="block mb-2">Payment Breakdown</label>
              {formData.breakdownAmounts.map((item, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-2 relative mb-6 bg-gray-50 p-4 rounded-lg"
                >
                  <CustomDropDown
                    options={categoryValues.map((category) => category.value)}
                    onSelect={(value) => {
                      const newBreakdown = [...formData.breakdownAmounts];
                      newBreakdown[index].category = value;
                      setFormData({
                        ...formData,
                        breakdownAmounts: newBreakdown,
                      });
                    }}
                    value={item.category}
                    label="Category"
                    width="w-full"
                  />
                  <div className="flex flex-col gap-2">
                    <label htmlFor="amount">Amount</label>
                    <input
                      type="number"
                      placeholder="Amount"
                      className="border p-2 rounded w-full bg-transparent"
                      value={item.amount || ""}
                      onChange={(e) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].amount =
                          e.target.value === "" ? 0 : Number(e.target.value);
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-2">
                    <CustomDropDown
                      options={departments.map((department) => department.name)}
                      onSelect={(value) => {
                        const newBreakdown = [...formData.breakdownAmounts];
                        newBreakdown[index].department = value;
                        setFormData({
                          ...formData,
                          breakdownAmounts: newBreakdown,
                        });
                      }}
                      label="Department"
                      width="w-full"
                      value={item.department}
                    />
                  </div>

                  <X
                    onClick={() => {
                      const newBreakdown = formData.breakdownAmounts.filter(
                        (_, i) => i !== index
                      );
                      setFormData({
                        ...formData,
                        breakdownAmounts: newBreakdown,
                      });
                    }}
                    className="bg-red-500 text-white px-2 rounded absolute top-0 right-0"
                  />
                </div>
              ))}
              <ButtonFill
                handleClick={() => {
                  setFormData({
                    ...formData,
                    breakdownAmounts: [
                      ...formData.breakdownAmounts,
                      {
                        category: "",
                        amount: 0,
                        department: "",
                      },
                    ],
                  });
                }}
              >
                Add Breakdown
              </ButtonFill>
            </div>

            {/* Added By Section */}

            {/* Created At Section */}
            <div className="flex flex-row gap-2">
              <div className="mt-4">
                <label className="block mb-2">Revenue Date</label>
                <input
                  type="date"
                  placeholder="Created At"
                  className="border p-2 rounded"
                  value={formData.createdAt.toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      createdAt: new Date(e.target.value),
                    })
                  }
                />
              </div>

              {/* <div className="mt-4">
                <label className="block mb-2">Payment Date</label>
                <input
                  type="date"
                  placeholder="Payment Date"
                  className="border p-2 rounded"
                  value={formData.payment_date.toISOString().split("T")[0]}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      payment_date: new Date(e.target.value),
                    })
                  }
                />
              </div> */}
              <div className="mt-4">
                <label className="block mb-2">Screenshot</label>
                <input
                  type="file"
                  className="border p-2 rounded"
                  accept="image/png, image/jpeg"
                  onChange={async (e) => {
                    //upload in base64
                    const file = e.target.files?.[0];
                    if (file) {
                      const base64 = await convertToBase64(file);
                      setFormData({
                        ...formData,
                        screenshot: base64 as string,
                      });
                    }
                  }}
                />
                {formData.screenshot && (
                  <img
                    src={formData.screenshot}
                    alt="Screenshot"
                    className="w-24 h-24 object-fit border mt-2 rounded"
                  />
                )}
              </div>
            </div>

            {/* Description Section */}
            <div className="mt-4 w-full">
              <label className="block mb-2">Description</label>
              <input
                type="text"
                placeholder="Description"
                className="border p-2 rounded w-full"
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
              />
            </div>

            {/* Screenshot Section */}

            {/* Submit Button */}
            <div className="mt-4 flex justify-end">
              <ButtonFill
                handleClick={handleSubmit}
                disabled={
                  !formData.revenue_name ||
                  !formData.revenue_type ||
                  !formData.employee_email
                }
              >
                {isEditMode ? "Update" : "Submit"} Payment
              </ButtonFill>
            </div>
            <Toaster />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewUnTrackedPaymentModal = ({
  ShowModel,
  setShowModel,
  selectedUnTrackedPayment,
}: {
  ShowModel: boolean;
  setShowModel: (value: boolean) => void;
  selectedUnTrackedPayment: any;
}) => {
  const GetDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div
      id="viewPaymentModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        ShowModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-6xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              View Revenue
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setShowModel(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          {/* TODO: Add Payment Details */}
          <div className="flex flex-col gap-4">
            <div className="flex flex-row gap-2">
              <b>Revenue Name:</b> {selectedUnTrackedPayment?.revenue_name}
            </div>
            <div className="flex flex-row gap-2">
              <b>Employee:</b> {selectedUnTrackedPayment?.employee_email}
            </div>
            <div className="flex flex-row gap-2">
              <b>Type:</b> {selectedUnTrackedPayment?.revenue_type}
            </div>
            <div className="flex flex-row gap-2">
              <b>Amount Paid:</b> {selectedUnTrackedPayment?.totalAmount}
            </div>

            <div className="flex flex-col gap-2">
              <b>Breakdown Amounts:</b>
              <div className="flex flex-row gap-2 w-full">
                <table className="w-full">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        No.
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Category
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Amount
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Department
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedUnTrackedPayment?.breakdownAmounts.map(
                      (item: any, index: number) => (
                        <tr key={index} className="border-b">
                          <td className="px-4 py-2 text-center">{index + 1}</td>
                          <td className="px-4 py-2 text-center">
                            {item?.category}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {item?.amount}
                          </td>
                          <td className="px-4 py-2 text-center">
                            {item?.department}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            {/* Description Section */}
            <div className="mt-4 w-full">
              <label className="block mb-2">Description</label>
              <div className="border p-2 rounded w-full">
                {selectedUnTrackedPayment?.description}
              </div>
            </div>

            <div className="flex flex-row gap-2 justify-between">
              <div className="flex justify-start gap-2">
                <b>Added By:</b> {selectedUnTrackedPayment?.addedBy}
              </div>
              <div className="flex justify-end gap-2">
                <b>Revenue Date:</b>{" "}
                {GetDate(selectedUnTrackedPayment?.createdAt)}
              </div>
              {/* <div className="flex justify-end gap-2">
                <b>Received Date:</b>{" "}
                {GetDate(selectedUnTrackedPayment?.payment_date)}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const InternalUnTrackedRevenueTable = ({
  unTrackedRevenue,
  setOpenAddRevenueModal,
  setModalType,
  setSelectedRevenue,
  setOpenViewRevenueModal,
  showEdit = true,
  GetDate,
  fetchUnTrackedRevenue,
}: {
  unTrackedRevenue: any[];
  setOpenAddRevenueModal: (value: boolean) => void;
  setModalType: (value: string) => void;
  setSelectedRevenue: (value: any) => void;
  setOpenViewRevenueModal: (value: boolean) => void;
  GetDate: (date: string) => string;
  fetchUnTrackedRevenue: () => void;
  showEdit?: boolean;
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(unTrackedRevenue);
  const [currentPage, setCurrentPage] = useState(1);

  // New filter states
  const [expenseStartDate, setExpenseStartDate] = useState("");
  const [expenseEndDate, setExpenseEndDate] = useState("");
//   const [paymentStartDate, setPaymentStartDate] = useState("");
//   const [paymentEndDate, setPaymentEndDate] = useState("");
  const [revenueName, setRevenueName] = useState("");
  const [amountSearch, setAmountSearch] = useState("");
  const [selectedRevenueType, setSelectedRevenueType] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  // Extract unique revenue types from data
  const revenueTypes = useMemo(() => {
    const types = new Set(
      unTrackedRevenue.map((revenue) => revenue.revenue_type)
    );
    return Array.from(types).filter((type) => type); // Filter out any null/undefined values
  }, [unTrackedRevenue]);

  // Extract unique departments from data
  const departments = useMemo(() => {
    const depts = new Set(
      unTrackedRevenue
        .filter((revenue) => revenue?.breakdownAmounts && Array.isArray(revenue.breakdownAmounts))
        .flatMap((revenue) =>
          revenue.breakdownAmounts
            .filter((breakdown) => breakdown?.department)
            .map((breakdown) => breakdown.department)
        )
    );
    return Array.from(depts).filter((dept) => dept);
  }, [unTrackedRevenue]);

  // Extract unique categories from data
  const categories = useMemo(() => {
    const cats = new Set(
      unTrackedRevenue
        .filter((revenue) => revenue?.breakdownAmounts && Array.isArray(revenue.breakdownAmounts))
        .flatMap((revenue) =>
          revenue.breakdownAmounts
            .filter((breakdown) => breakdown?.category)
            .map((breakdown) => breakdown.category)
        )
    );
    return Array.from(cats).filter((cat) => cat);
  }, [unTrackedRevenue]);

  const handleFilters = () => {
    let filtered = [...unTrackedRevenue];

    // Filter by expense date range
    if (expenseStartDate) {
      filtered = filtered.filter(
        (revenue) => new Date(revenue.createdAt) >= new Date(expenseStartDate)
      );
    }
    if (expenseEndDate) {
      filtered = filtered.filter(
        (revenue) => new Date(revenue.createdAt) <= new Date(expenseEndDate)
      );
    }

    // Filter by payment date range
    // if (paymentStartDate) {
    //   filtered = filtered.filter(
    //     (revenue) =>
    //       new Date(revenue.payment_date) >= new Date(paymentStartDate)
    //   );
    // }
    // if (paymentEndDate) {
    //   filtered = filtered.filter(
    //     (revenue) => new Date(revenue.payment_date) <= new Date(paymentEndDate)
    //   );
    // }

    // Filter by revenue name
    if (revenueName) {
      filtered = filtered.filter((revenue) =>
        revenue?.revenue_name?.toLowerCase().includes(revenueName.toLowerCase())
      );
    }

    // Filter by amount
    if (amountSearch) {
      filtered = filtered.filter((revenue) =>
        revenue.totalAmount.toString().includes(amountSearch)
      );
    }

    // Filter by revenue type
    if (selectedRevenueType) {
      filtered = filtered.filter(
        (revenue) => revenue.revenue_type === selectedRevenueType
      );
    }

    // Filter by department
    if (selectedDepartment) {
      filtered = filtered.filter((revenue) =>
        revenue?.breakdownAmounts && Array.isArray(revenue.breakdownAmounts)
          ? revenue.breakdownAmounts.some(
              (breakdown) => breakdown?.department === selectedDepartment
            )
          : false
      );
    }

    // Filter by category
    if (selectedCategory) {
      filtered = filtered.filter((revenue) =>
        revenue?.breakdownAmounts && Array.isArray(revenue.breakdownAmounts)
          ? revenue.breakdownAmounts.some(
              (breakdown) => breakdown?.category === selectedCategory
            )
          : false
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setExpenseStartDate("");
    setExpenseEndDate("");
    // setPaymentStartDate("");
    // setPaymentEndDate("");
    setRevenueName("");
    setAmountSearch("");
    setSelectedRevenueType("");
    setSelectedDepartment("");
    setSelectedCategory("");
    setFilteredData(unTrackedRevenue);
    setCurrentPage(1);
  };

  const DeleteUnTrackedRevenue = async (id: string) => {
    try {
      const response = await apiHandler({
        name: "deleteUnTrackedRevenue",
        params: [id],
      });
      if (response.data.isSuccess) {
        fetchUnTrackedRevenue();
        window.location.reload();
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete internal payment");
      console.error(error);
    }
  };

  const getPageData = () => {
    const startIndex = (currentPage - 1) * 20;
    const endIndex = startIndex + 20;
    return filteredData.slice(startIndex, endIndex);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const DownloadCSV = (data: any[]) => {
    //prepare headers
    const headers = ["Expense Date", "Payment Date", "Revenue Name", "Amount Paid", "Description", "Screenshot", "Added By", "Paid By Account"];
    //prepare rows
    const rows = data.map((revenue) => [
      GetDate(revenue?.createdAt),
      GetDate(revenue?.payment_date),
      revenue?.revenue_name,
      revenue?.totalAmount,
      revenue?.description,
      revenue?.screenshot,
      revenue?.addedBy,
      revenue?.paidByOurAccounts?.[0]
    ]);
    const csvContent = [headers, ...rows].map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'incoming-revenue.csv';
    a.click();
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        {/* Expense Date Filters */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Revenue Date Range</label>
          <div className="flex gap-2">
            <input
              type="date"
              value={expenseStartDate}
              onChange={(e) => setExpenseStartDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
            <input
              type="date"
              value={expenseEndDate}
              onChange={(e) => setExpenseEndDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
          </div>
        </div>

        {/* Payment Date Filters */}
        {/* <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">
            Payment Received Date Range
          </label>
          <div className="flex gap-2">
            <input
              type="date"
              value={paymentStartDate}
              onChange={(e) => setPaymentStartDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
            <input
              type="date"
              value={paymentEndDate}
              onChange={(e) => setPaymentEndDate(e.target.value)}
              className="border p-2 rounded w-full bg-transparent"
            />
          </div>
        </div> */}

        {/* Revenue Name Search */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Revenue Name</label>
          <input
            type="text"
            placeholder="Search revenue name..."
            value={revenueName}
            onChange={(e) => setRevenueName(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          />
        </div>

        {/* Amount Search */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Amount</label>
          <input
            type="text"
            placeholder="Search amount..."
            value={amountSearch}
            onChange={(e) => setAmountSearch(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          />
        </div>

        {/* Revenue Type Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Revenue Type</label>
          <select
            value={selectedRevenueType}
            onChange={(e) => setSelectedRevenueType(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Types</option>
            {revenueTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        {/* Department Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Department</label>
          <select
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Departments</option>
            {departments.map((department) => (
              <option key={department} value={department}>
                {department}
              </option>
            ))}
          </select>
        </div>

        {/* Category Dropdown */}
        <div className="flex flex-col gap-2">
          <label className="text-sm font-medium">Category</label>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="border p-2 rounded w-full bg-transparent"
          >
            <option value="">All Categories</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex justify-end gap-2 mb-4">
        <button
          className="bg-primary text-white p-2 rounded px-4 shadow-md font-bold"
          onClick={handleFilters}
        >
          Apply Filters
        </button>
        <button
          className="bg-gray-500 text-white p-2 rounded px-4 shadow-md font-bold"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
        <button
          className="bg-primary text-white p-2 rounded px-4 shadow-md font-bold"
          onClick={() => DownloadCSV(filteredData)}
        >
          Download CSV
        </button>
      </div>

      <div className="flex flex-row mb-4">
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setFilteredData(
              unTrackedRevenue.filter(
                (revenue) =>
                  revenue?.revenue_name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()) ||
                  GetDate(revenue?.createdAt).includes(e.target.value)
              )
            );
            setCurrentPage(1);
          }}
          className="border p-2 rounded w-full bg-transparent"
        />
        <button
          className="bg-primary text-white p-2 rounded"
          onClick={() => {
            setSearchText("");
            setFilteredData(unTrackedRevenue);
            setCurrentPage(1);
          }}
        >
          Reset
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Expense Date
              </th>
              <th scope="col" className="px-4 py-4">
                Payment Received Date
              </th>
              <th scope="col" className="px-4 py-4">
                Revenue Name
              </th>
              <th scope="col" className="px-4 py-4">
                Revenue Type
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Description
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Amount Paid
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Screenshot
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {getPageData().map((revenue) => (
              <tr className="border-b" key={revenue?._id}>
                <td className="px-4 py-3">{GetDate(revenue?.createdAt)}</td>
                <td className="px-4 py-3">{GetDate(revenue?.payment_date)}</td>
                <td className="px-4 py-3">
                  {revenue?.revenue_name === "" ? "N/A" : revenue?.revenue_name}
                </td>
                <td className="px-4 py-3">
                  {revenue?.revenue_type === "" ? "N/A" : revenue?.revenue_type}
                </td>
                <td className="px-4 py-3">
                  {revenue?.description === "" ? "N/A" : revenue?.description}
                </td>
                <td className="px-4 py-3">
                  {revenue?.totalAmount === 0 ? "N/A" : revenue?.totalAmount}
                </td>
                <td className="px-4 py-3">
                  <div
                    className="text-blue-500 cursor-pointer underline"
                    onClick={() => {
                      window.open(revenue?.screenshot, "_blank");
                    }}
                  >
                    Screenshot Link
                  </div>
                </td>
                <td className="px-4 py-3 text-center flex items-center justify-center">
                  <div className="cursor-pointer flex items-center gap-4">
                    {showEdit && (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setOpenAddRevenueModal(true);
                          setModalType("edit");
                          setSelectedRevenue(revenue);
                        }}
                      >
                        <EditIcon size="w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300" />
                      </div>
                    )}
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenViewRevenueModal(true);
                        setSelectedRevenue(revenue);
                      }}
                    >
                      <EyeIcon size="w-6 h-6 hover:text-primary hover:scale-110 transition-all duration-300" />
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        DeleteUnTrackedRevenue(revenue?._id);
                      }}
                    >
                      <DeleteIcon size="w-6 h-6 hover:text-red-500 hover:scale-110 transition-all duration-300" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex flex-row gap-2 justify-center items-center">
          <button
            disabled={currentPage === 1}
            className="bg-primary text-white p-2 rounded px-4 shadow-md font-bold disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            Previous
          </button>
          <div className="flex flex-row gap-2 items-center justify-center p-2 rounded-md bg-primary text-white px-4 min-w-10 shadow-md font-bold">
            {currentPage} / {Math.ceil(filteredData.length / 20)}
          </div>
          <button
            disabled={currentPage === Math.ceil(filteredData.length / 20)}
            className="bg-primary text-white p-2 rounded px-4 shadow-md font-bold disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
