import { Input, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";

import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import FilterColumnsModal from "./FilterColumnsModal";
import TableHeader from "./TableHeader";
import { GlobalTableProps } from "./types";
import { getTagColor } from "./functions";




const GlobalTable: React.FC<GlobalTableProps> = ({
  data,
  tableName,
  columns,
  otherColumns = [],
  statusList = [],
  size = "large",
  isColumnWidth = false,
  isExpandable = false,
  loading,
  bordered = false,
  searchPlaceholder = "Search",
  isRowSelection = false,
  rowSelectionProps,
  updatedTableData,
  showOnlyPaidUsers,
  desktopScrollWidth,
  setStartPage,
  changePage,
}) => {
  const [tableSize, setTableSize] = useState(size);
  const [afterArrangeColumns, setAfterArrangeColumns] = useState(columns);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [filteredData, setFilteredData] = useState(data);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  useEffect(() => {
    if (setStartPage && setStartPage > 1) {
      setPage(setStartPage);
    }
  }, [setStartPage]);

  const showModal = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, []);

  const handleSearch = (value: string) => {
    const filtered = data?.filter((record) => {
      // First check _id if it exists
      if (record._id) {
        return record._id.toString().toLowerCase().includes(value.toLowerCase());
      }
      // Then check all other values
      return Object.values(record).some((val) =>
        val?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setSearchText(value);
    setPage(1);
  };

  const components = {
    header: {
      cell: (props: React.HTMLProps<HTMLTableHeaderCellElement>) => (
        <th
          {...props}
          className="text-xs text-gray-700 uppercase bg-gray-50"
        />
      ),
    },
  };

  const items = [
    {
      key: "1",
      label: <div onClick={() => setTableSize("large")}>Large</div>,
    },
    {
      key: "2",
      label: <div onClick={() => setTableSize("middle")}>Middle</div>,
    },
    {
      key: "3",
      label: <div onClick={() => setTableSize("small")}>Small</div>,
    },
  ];

  // only used in marketing download page
  useEffect(() => {
    setFilteredData(data);
  }, [updatedTableData]);
  useEffect(() => {
    setFilteredData(data);
  }, [showOnlyPaidUsers]);

  // end of marketing download page


  const handleChangeStatus = (key: string | number) => {
    if (key === "-1" || key === -1) {
      setFilteredData(data);
    } else {
      const currentStatusData = data?.filter((row) => row.statusValue === key);
      console.log("currentStatusData", currentStatusData, key, data)
      setFilteredData(currentStatusData);
    }
  };

  useEffect(() => {
    setFilteredData(data);
  }, [loading]);
  const scrollConfig = isColumnWidth
    ? { x: afterArrangeColumns?.length * 150 }
    : desktopScrollWidth ? { x: desktopScrollWidth }
      : screenWidth < 768
        ? { x: 1300 } // Horizontal scroll for small screens
        : undefined;
  return (
    <div className="w-full h-full p-2">
      {tableName && (
        <h1 className="text-2xl text-gray-700 font-bold">{tableName}</h1>
      )}
      <div className="bg-white shadow-md rounded-lg p-2">
        <TableHeader
          searchText={searchText}
          statusList={statusList}
          handleChangeStatus={handleChangeStatus}
          searchPlaceholder={searchPlaceholder}
          handleSearch={handleSearch}
          showModal={showModal}
          items={items}
        />

        <Table
          dataSource={filteredData}
          bordered={bordered}
          columns={afterArrangeColumns}
          components={components}
          loading={loading}
          size={tableSize}
          scroll={{ ...scrollConfig, scrollToFirstRowOnChange: true, }}
          rowSelection={isRowSelection ? { ...rowSelectionProps } : undefined}
          expandable={
            isExpandable
              ? {
                expandedRowRender: (data: any) => (
                  <p style={{ margin: 0 }}>
                    {data?.isTags ? (
                      <div className="flex flex-wrap gap-1">
                        {data?.expandData?.map(
                          (tag: string, index: number) => (
                            <Tag
                              className=""
                              color={getTagColor(index)}
                              key={tag}
                            >
                              {tag}
                            </Tag>
                          )
                        )}
                      </div>
                    ) : (
                      <>{data?.expandData}</>
                    )}
                  </p>
                ),
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <MinusCircleTwoTone
                      onClick={(e) => onExpand(record, e)}
                    />
                  ) : (
                    <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                  ),
              }
              : undefined
          }
          pagination={
            filteredData?.length <= 5
              ? false
              : {
                position: ["bottomCenter"],
                current: page,
                pageSize: pageSize,
                showSizeChanger: true,
                defaultPageSize: 20, // Default page size set to 50
                pageSizeOptions: ["20", "50", "100"],
                showTotal: (total, range) => (
                  <div className="flex justify-start items-start">
                    <span>{`${range[0]}-${range[1]} of ${total} items`}</span>
                  </div>
                ),
                onChange: (page, pageSize) => {
                  //change the url params
                  if (changePage) {
                    changePage(page);
                  }

                  setPageSize(pageSize);
                  setPage(page);
                },
              }
          }
        />
        <FilterColumnsModal
          columns={columns}
          otherColumns={otherColumns}
          setAfterArrangeColumns={setAfterArrangeColumns}
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      </div>
    </div>
  );
};

export default GlobalTable;
