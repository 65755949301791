import CustomSelect from "shared-components/form-fields/custom-select";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import {
  accessData,
  provinceData,
  uniYearData,
} from "constants/constantvalues";
import CustomTextAreaField from "shared-components/form-fields/custom-textarea-field";

const VaultFieldsComponent = ({
  formik,
  institutesData,
  subjectData,
  topicsData,
  vaultType,
  boardsData,
  modules,
  disciplines,
}) => {
  const { values } = formik;

  // Custom styles for react-select
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: "48px",
      height: "48px",
      borderRadius: "0.375rem",
      borderColor:
        formik.touched.topicName && formik.errors.topicName
          ? "#ef4444"
          : "#e5e7eb",
      "&:hover": {
        borderColor: "#9ca3af",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
    }),
  };

  return (
    <div className="flex flex-col gap-2">
      {process.env.REACT_APP_CATEGORY === "MEDSCHOOL" && (
        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full md:w-1/2">
            <CustomSelect
              formik={formik}
              label={" Select  Universities"}
              mode="multiple"
              name="universities"
              options={institutesData}
            />
          </div>
          <div className="md:p-2 w-full md:w-1/2">
            <CustomSelect
              formik={formik}
              name="year"
              label={"  Select  Year"}
              options={uniYearData}
            />
          </div>
        </div>
      )}
      <div className="flex flex-col md:flex-row ">
        <div className="md:p-2 w-full   md:w-1/2   ">
          <CustomSelect
            formik={formik}
            label={" Province"}
            name="province"
            options={provinceData}
          />
        </div>
        <div className="md:p-2 w-full  md:w-1/2 ">
          <CustomSelect
            formik={formik}
            name="subject"
            require={
              process.env.REACT_APP_CATEGORY === "MEDSCHOOL" ? false : true
            }
            label={
              process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
                ? "Discipline"
                : "Subject"
            }
            options={
              process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
                ? disciplines
                : subjectData
            }
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row ">
        <div className="md:p-2 w-full   md:w-1/2   ">
          <CustomSelect
            formik={formik}
            name="board"
            label={" Board"}
            options={boardsData}
          />
        </div>
        <div className="md:p-2 w-full  md:w-1/2 ">
          <CustomSelect
            formik={formik}
            name="access"
            label={" Access"}
            options={accessData}
          />
        </div>
      </div>
      {process.env.REACT_APP_CATEGORY === "MEDSCHOOL" && (
        <div className="flex flex-col ml-3">
          <CustomSelect
            formik={formik}
            label={" Modules"}
            name="modules"
            options={modules}
          />
        </div>
      )}

      <div className="flex flex-col md:flex-row gap-2">
        <div
          className={`w-full ${vaultType !== "Shortlisting" ? "md:w-1/2" : ""}`}
        >
          <div className="md:p-2 w-full">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              Topic Name <span className="text-red-500">*</span>
            </label>
            <Select
              placeholder="Select Topic"
              value={topicsData?.find(
                (topic) => topic.topicName === formik.values.topicName
              )}
              onChange={(option) => {
                formik.setFieldValue("topicName", option?.topicName || null);
                // Reset subtopic completely
                formik.setFieldValue("subTopicName", "");
                formik.setFieldTouched("subTopicName", false);
                formik.setFieldError("subTopicName", undefined);
              }}
              options={topicsData}
              getOptionLabel={(option) => option.topicName}
              getOptionValue={(option) => option.topicName}
              isClearable
              styles={customStyles}
              isSearchable
              className="w-full"
            />
            {formik.touched.topicName && formik.errors.topicName && (
              <div className="text-red-500 text-sm mt-1">
                {formik.errors.topicName}
              </div>
            )}
          </div>
        </div>
        {vaultType !== "Shortlisting" && (
          <div className="md:p-2 w-full md:w-1/2">
            <label className="block mb-2 text-sm font-medium text-gray-600">
              SubTopic Name
            </label>
            <Select
              placeholder="Select SubTopic"
              value={
                formik.values.subTopicName
                  ? topicsData
                      ?.find(
                        (topic) =>
                          topic?.topicName === formik?.values?.topicName
                      )
                      ?.subTopics?.find(
                        (subTopic) =>
                          subTopic?.subTopicName ===
                          formik?.values?.subTopicName
                      )
                  : null
              }
              onChange={(option) =>
                formik.setFieldValue("subTopicName", option?.subTopicName || "")
              }
              options={
                topicsData?.find(
                  (topic) => topic?.topicName === formik?.values?.topicName
                )?.subTopics || []
              }
              getOptionLabel={(option) => option?.subTopicName}
              getOptionValue={(option) => option?.subTopicName}
              isClearable
              isDisabled={!formik?.values?.topicName}
              styles={customStyles}
              isSearchable
              className="w-full"
            />
            {formik?.touched?.subTopicName && formik?.errors?.subTopicName && (
              <div className="text-red-500 text-sm mt-1">
                {formik?.errors?.subTopicName}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col gap-2">
        <CustomTextAreaField formik={formik} label="Description" name="desc" />
      </div>
    </div>
  );
};

export default VaultFieldsComponent;
