const Path: { [key: string]: string } = {
  topics: "/manage/topics",
  courses: "/courses/:admin",
  editCourse: "/editCourse/:id",
  login: "/signin",
  dashboard: "/dashboard",

  notes: "/manage/notes",
  shortlistings: "/manage/shortlistings",
  mnemonics: "/manage/mnemonics",
  cheatsheets: "/manage/cheatsheets",
  essentials: "/manage/essentials",
  snapCourses: "/manage/snapcourses",
  topicalGuides: "/manage/guides",

  selectFlashcard: "/selectFlashcard",
  addFlashcard: "/addFlashcard/:id",
  editFlashcard: "/editFlashcard/:id/:flashId/:report",
  flashcardReport: "/flashcardReports",
  scholarShip: "/ScholarshipAdmin",
  sendNotification: "/send-notification",

  features: "/manage/features",
  teachers: "/manage/teachers",
  hierarchy: "/manage/hierarchy",
  marketingDownload: "/marketing-download",
  //  statistics: "/new-Statistics",
  team: "/manage/team",
  department: "/manage/department",
  testimonials: "/manage/testimonials",

  //academics
  categoryManager: "/manage/categories",
  categoryDeckgroups: "/deckgroups/:categoryName",
  categoryDeckgroupDecks: "/decks/:categoryName/:deckGroupName",
  deckManager: "/deck/:deckName",
  addQuestion: "/addquestion",
  editQuestion: "/editQuestion/:id",
  editQuestionReport: "/editQuestion/:id/:reportId",
  questionDetails: "/questionDetails/:id",

  headQuarters: "/hq",
  renewals: "/manage/renewals",
  manageAssignments: "/manageAssignment/:assignmentId",
  courseSchedule: "/schedule/:id",
  caManager: "/manage/ca",
  gallery: "/manage/gallery",

  modulesManagement: "/manage/modules",
  disciplineManagement: "/manage/disciplines",
  deckStatistics: "/deck/statistics",
  universityVerification: "/manage/university-verification",

  referenceManagement: "/manage/references",
  conversationsManagement: "/manage/conversions",

  usermanagement: "/manage/users",
  institute: "/manage/institutions",

  plansManager: "/manage/plans", 
  editPackage: "/manage-package/:id",
  myOwnBundles: "/manage/mybundles",
  editCustomBundles: "/custom-bundle/:id",
  // experts
  experts: "/experts",
  userExpertDoubts: "/user-expert-doubt",
  expertDashboard: "/expert-dashboard",
  expertSubject: "/experts/:subject",
  expertDashboardSubject: "/expert-dashboard/:subject",
  expertInterface: "/expert-interface/:type/:id",
  manageExperts: "/manage-experts",
  expertSubjectManage: "/experts/:subject/manage",
  mySubjectiveDoubts:"/my-subjective-doubts",

  //adminManager: "/adminmanager",
  solveDoubtById: "/solveDoubt/:id",
  videoInterface: "/videoInterface",

  filterQuestions: "/filter-questions",
  manageJobs: "/managejobs",
  editJob: "/editJob/:id",
  addNewJob: "/addnewjob",

  //latest-commission-system
  latestCommissionDashboard: "/manage/commission/dashboard",
  ManageAssociates: "/manage/commission/associates",
  ManageHeads: "/manage/commission/heads",
  // ManageManagers: "/manage/commission/managers",
  ManageNationalDirectors: "/manage/commission/national-directors",
  ManageProvincialDirectors: "/manage/commission/provincial-directors",
  ViewCommissionUserDetail: "/manage/commission/view/:id",
  ReimbursementRequests: "/manage/commission/reimbursements",
  UpdateCommissionPercentage: "/manage/commission/commission",
  PromoteHeadToManager: "/manage/commission/promote/head-to-manager",
  transferUsers: "/manage/commission/transfer-user",
  ViewStatistics: "/view-Statistics",
  generateURL: "/generate-url",
  couponManager: "/manage/commission/coupons",
  viewCouponDetails: "/manage/commission/coupon/:id",

  employeeProviderFinance: "/manage/employee-provider-finance",
  managePayments: "/manage/payments",
  revenue: "/revenue",
  manageOrders: "/oms",
  manageOrderStats: "/oms-manager",
  verify: "/verifyOrder/:entity/:orderid",
  bankManager: "/manage/finances",
  bankHistory: "/manage/history/:category/:id",
  courseManager: "/manage/courses",
  paymentHistory: "/manage/payment-history/:type/:id",
  oldRevenueData: "/old-revenue-data",

  auditedQuestionHistoryView: "/manage/audit-question/:id",
  questionAudit: "/audit-questions",
  AuditQuestionStats: "/educational-dashboard",
  authorStatistics: "/author/:author",

  suggestResouces: "/manage/suggest-resources",
  subjects: "/manage/subjects",
  
  //New-commission-system
  ManageDirectors: "/manage/commission/directors",
  ManageManagers: "/manage/commission/managers",
  auditQuestionReports: "/manage/audit-questions-reports",
  marketingCampaign: "/manage/marketing-campaign",
  marketingCampaignView: "/view-marketing-campaign-users/:marketingCampaignId",
  marketingCampaignUrls: "/view-marketing-campaign-links/:marketingCampaignId",
  ManageTargets: "/manage/targets",
  marketingUserDetails: "/marketing/user-details",  
  pendingSales: "/manage/commission/sales",  
  csaDashboard:"/manage/commission/csa",
  
  // Medschool Promo codes
  promocodes: "/manage/promocodes",
  viewCodeStats: "/manage/promocodes/:id",
  // pricing
  pricingBundles:"/bundles/:slug",
  bundles:"/bundles",
  checkout:'/checkout/cart',
  vaultReportedIssues:"/manage/vault/issues",
  blogReportedIssues:"/manage/blog-issues",

  new_courses: "/manage/new-courses",
  view_all_new_courses: "/view-all-new-courses",
  //old courses
  oldCourses: "/old-courses/:id",

  // free trial
  freeTrial: "/manage/free-trial",
  trialLeads: "/manage/trial-leads/:email",

  //Flashcards New 
  flashcardsDecks: "/manage/flashcards/decks",
  flashcardsDecksView: "/manage/flashcards/decks/:id",
  flashcardsSubjects: "/manage/flashcards/subject/:id",
  flashcardsReport: "/manage/flashcards/reports",
  textbookManager: "/manage/textbooks",
  pastPaperManager: "/manage/past-papers",
  questionaire: "/questionaires",
  questionaireView: "/questionnaires/:id",

};

export default Path;
